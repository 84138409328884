import { formatDate } from '@angular/common';
import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { MaximumActiveCampaignsExceededException } from '../core/exceptions/maximum-active-campaigns-exceeded-exception';
import { NoActiveSubscriptionException } from '../core/exceptions/no-active-subscription-exception';
import { UnknownErrorException } from '../core/exceptions/unknown-error-exception';
import { CampaignModel } from '../models/campaign-model';
import { CampaignRewardModel } from '../models/campaign-reward-model';
import { CompanyModel } from '../models/company-model';
import { CreateCampaignModel } from '../models/create-campaign-model';
import { Page } from '../models/paging/page-model';
import { ValidationException } from '../core/exceptions/validation-exception';
import { UpdateCampaignModel } from '../models/update-campaign-model';
import { StampsObjectivesCantBeLowerThanOriginalException } from '../core/exceptions/stamps-objective-cant-be-lower-than-original-exception';

export class CreateCampaignApiModel {
  name: string;
  description: string;
  startDate: string;
  endDate: string;
  stampsObjective: number;
  campaignRewardName: string;
  campaignRewardDescription: string;
  voucherDuration: string;    
  recurrencePattern: string;
}

export class UpdateCampaignApiModel {
  name: string;
  description: string;
  startDate: string;
  endDate: string;
  stampsObjective: number;
  campaignRewardName: string;
  campaignRewardDescription: string;
  voucherDuration: string;    
  recurrencePattern: string;
  campaignStatus: string;
}

@Injectable({
  providedIn: 'root'
})
export class CampaignService {
  constructor(private http: HttpClient) { }

  getCampaigns(companyId: string, currentPage: number, pageSize: number): Observable<Page<CampaignModel>> {
    let httpParams = new HttpParams();
    httpParams = httpParams.append("page", currentPage.toString());
    httpParams = httpParams.append("size", pageSize.toString());

    const httpOptions = {
      headers: new HttpHeaders({
        ContentType: 'application/json',
      }),
      params: httpParams
    };

    const url = environment.apiUrl + '/api/partner/companies/' + companyId + '/campaigns';
    const result = this.http.get<Page<CampaignModel>>(url, httpOptions);
    return result.pipe(
      catchError(
        this.handleError<Page<CampaignModel>>('getCampaigns', null)
      )
    );
  }

  getCampaign(companyId: string, campaignId: number): Observable<CampaignModel> {
    const httpOptions = {
      headers: new HttpHeaders({
        ContentType: 'application/json',
      }),
    };

    const url = environment.apiUrl + '/api/partner/companies/' + companyId + '/campaigns/' + campaignId;
    const result = this.http.get<CampaignModel>(url, httpOptions);
    return result.pipe(
      map(data => this.mapFromData(data)),
      catchError(
        this.handleError<CampaignModel>('getCampaign', null)
      )
    );
  }

  createCampaign(companyId: string, createCampaign: CreateCampaignModel): Observable<CampaignModel> {
    const httpOptions = {
      headers: new HttpHeaders({
        ContentType: 'application/json',
      }),
    };

    const createCampaignApiModel = new CreateCampaignApiModel();
    createCampaignApiModel.name = createCampaign.name;
    createCampaignApiModel.description = createCampaign.description;
    createCampaignApiModel.startDate = formatDate(createCampaign.startDate.toUTCString(), 'yyyy-MM-ddTHH:mm:ssZ', 'en-US', 'UTC');
    createCampaignApiModel.endDate = formatDate(createCampaign.endDate.toUTCString(), 'yyyy-MM-ddTHH:mm:ssZ', 'en-US', 'UTC');
    createCampaignApiModel.stampsObjective = createCampaign.stampsObjective;
    createCampaignApiModel.campaignRewardName = createCampaign.campaignRewardName;
    createCampaignApiModel.campaignRewardDescription = createCampaign.campaignRewardDescription;
    createCampaignApiModel.voucherDuration = createCampaign.voucherDuration;
    createCampaignApiModel.recurrencePattern = createCampaign.recurrencePattern;

    const url = environment.apiUrl + '/api/partner/companies/' + companyId + '/campaigns';
    const result = this.http.post<CampaignModel>(url, createCampaignApiModel, httpOptions);
    return result.pipe(
      map(data => {
        return this.mapFromData(data);
      }),
      catchError((response: HttpErrorResponse) => {
        if (response.status == 400) {
          if (response.error.violations) {
            const validationException = new ValidationException(response.error.violations);
            return throwError(() => validationException);
          }
          return throwError(() => new UnknownErrorException());
        }
        else if (response.status == 403) {
          if (response.error.title == 'Maximum active campaigns exceeded.') {
            return throwError(() => new MaximumActiveCampaignsExceededException());
          }
        }
        return throwError(() => this.handleError('createCampaign', null));
      })
    );
  }

  deleteCampaign(companyId: string, campaignId: number) {
    const httpOptions = {
      headers: new HttpHeaders({
        ContentType: 'application/json',
      }),
    };

    const url = environment.apiUrl + '/api/partner/companies/' + companyId + '/campaigns/' + campaignId;
    const result = this.http.delete(url, httpOptions);
    return result.pipe(catchError(this.handleError('deleteCampaign', null)));
  }

  updateCampaign(companyId: string, campaignId: number, updateCampaign: UpdateCampaignModel) {
    const httpOptions = {
      headers: new HttpHeaders({
        ContentType: 'application/json',
      }),
    };

    const updateCampaignApiModel = new UpdateCampaignApiModel();
    updateCampaignApiModel.name = updateCampaign.name;
    updateCampaignApiModel.description = updateCampaign.description;
    updateCampaignApiModel.startDate = formatDate(updateCampaign.startDate.toUTCString(), 'yyyy-MM-ddTHH:mm:ssZ', 'en-US', 'UTC');
    updateCampaignApiModel.endDate = formatDate(updateCampaign.endDate.toUTCString(), 'yyyy-MM-ddTHH:mm:ssZ', 'en-US', 'UTC');
    updateCampaignApiModel.stampsObjective = updateCampaign.stampsObjective;
    updateCampaignApiModel.campaignRewardName = updateCampaign.campaignRewardName;
    updateCampaignApiModel.campaignRewardDescription = updateCampaign.campaignRewardDescription;
    updateCampaignApiModel.voucherDuration = updateCampaign.voucherDuration;
    updateCampaignApiModel.recurrencePattern = updateCampaign.recurrencePattern;
    updateCampaignApiModel.campaignStatus = updateCampaign.campaignStatus;

    const url = environment.apiUrl + '/api/partner/companies/' + companyId + '/campaigns' + campaignId;
    const result = this.http.put<CampaignModel>(url, updateCampaignApiModel, httpOptions);
    return result.pipe(
      map(data => this.mapFromData(data)),
      catchError((response: HttpErrorResponse) => {
        if (response.status == 400) {
          if (response.error.violations) {
            const validationException = new ValidationException(response.error.violations);
            return throwError(() => validationException);
          }
          return throwError(() => new UnknownErrorException());
        }
        else if (response.status == 409) {
          if(response.error.title == 'Stamps objective can\'t be lower than original.'){
            return throwError(() => new StampsObjectivesCantBeLowerThanOriginalException());
          }
          if (response.error == 'No active subscriptionplan order found') {
            return throwError(() => new NoActiveSubscriptionException());
          }
          else if (response.error == 'Maximum active campaigns exceeded') {
            return throwError(() => new MaximumActiveCampaignsExceededException());
          }
          else {
            return throwError(() => new UnknownErrorException());
          }
        }
        return throwError(() => this.handleError('updateCampaign', null));
      })
    );
  }

  /**
     * Handle Http operation that failed.
     * Let the app continue.
     * @param operation - name of the operation that failed
     * @param result - optional value to return as the observable result
     */
  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {

      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead

      // TODO: better job of transforming error for user consumption
      // this.log(`${operation} failed: ${error.message}`);

      // Let the app keep running by returning an empty result.
      return of(result);
    };
  }

  private mapFromData(data: CampaignModel): CampaignModel {
    const campaign = new CampaignModel();
    campaign.id = data.id;
    campaign.name = data.name;
    campaign.description = data.description;
    campaign.campaignStatus = data.campaignStatus;
    campaign.startDate = data.startDate;
    campaign.endDate = data.endDate;
    campaign.stampsObjective = data.stampsObjective;
    campaign.voucherDuration = data.voucherDuration;
    campaign.recurrencePattern = data.recurrencePattern;

    campaign.company = new CompanyModel(
      data.company.id,
      data.company.name,
      data.company.description,
      data.company.logo,
      data.company.email,
      data.company.url);

    campaign.campaignReward = new CampaignRewardModel();
    campaign.campaignReward.id = data.campaignReward.id;
    campaign.campaignReward.name = data.campaignReward.name;
    campaign.campaignReward.description = data.campaignReward.description;
    return campaign;
  }
}
