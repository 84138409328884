<div class="container">
  <div [ngClass]="{
    'container-item-xlarge': isXLarge(),
    'container-item-large': isLarge(),
    'container-item-medium': isMedium(),
    'container-item-small': isSmall(),
    'container-item-xsmall': isXSmall()
  }">
    <h1>Analyze</h1>
    <h2>Understand your customers and how your promotions and stamp cards are performing.</h2>
    <mat-list>
      <mat-list-item [ngClass]="{
        'list-item-xsmall': isXSmall()
      }">
        <span matListItemIcon>
          <mat-icon color="primary">eco</mat-icon>
        </span>
        <div matListItemTitle class="list-item-title">Loyal customers generate the highest revenue.</div>
      </mat-list-item>
      <mat-list-item [ngClass]="{
        'list-item-xsmall': isXSmall()
      }">
        <span matListItemIcon>
          <mat-icon color="primary">eco</mat-icon>
        </span>
        <div matListItemTitle class="list-item-title">See which promotion or stamp card is working best for your business.</div>
      </mat-list-item>
      <mat-list-item [ngClass]="{
        'list-item-xsmall': isXSmall()
      }">
        <span matListItemIcon>
          <mat-icon color="primary">eco</mat-icon>
        </span>
        <div matListItemTitle class="list-item-title">Adjust your business strategy with new insights.</div>

      </mat-list-item>
    </mat-list>
  </div>

  <div [ngClass]="{
    'container-item-card-xlarge': isXLarge(),
    'container-item-card-large': isLarge(),
    'container-item-card-medium': isMedium(),
    'container-item-card-small': isSmall(),
    'container-item-card-xsmall': isXSmall()
  }">
    <mat-card appearance="outlined" class="column-card mat-component-background mat-elevation-z2">
      <mat-card-header>
        <div mat-card-avatar class="dinner-avatar"></div>
        <mat-card-title>Analytics</mat-card-title>
        <mat-card-subtitle>Serving Dinner : 16h - 22h </mat-card-subtitle>
      </mat-card-header>
      <div *ngIf="!isXSmall(); else xSmallContentImage">
        <img src="/assets/img/banners/small/product-banner-dinner-s.webp" alt="dinner-analytics" />
      </div>
      <mat-card-actions></mat-card-actions>
    </mat-card>
  </div>
</div>

<ng-template #xSmallContentImage>
  <div *ngIf="isXSmall();">
    <img src="/assets/img/banners/xsmall/product-banner-dinner-xs.webp" alt="dinner-analytics" />
  </div>
</ng-template>