<div class="container">
  <div [ngClass]="{
    'container-item-xlarge': isXLarge(),
    'container-item-large': isLarge(),
    'container-item-medium': isMedium(),
    'container-item-small': isSmall(),
    'container-item-xsmall': isXSmall()
  }">
    <h1>Planet VG Chefs</h1>
    <h2>We are ready to help you and your team to become more successful.</h2>
    <mat-list>
      <mat-list-item [ngClass]="{
        'list-item-xsmall': isXSmall()
      }">
        <span matListItemIcon>
          <mat-icon color="primary">eco</mat-icon>
        </span>
        <div matListItemTitle class="list-item-title">Feel free to contact us, to book a demo.</div>
      </mat-list-item>
      <mat-list-item [ngClass]="{
        'list-item-xsmall': isXSmall()
      }">
        <span matListItemIcon>
          <mat-icon color="primary">eco</mat-icon>
        </span>
        <div matListItemTitle class="list-item-title">Ask us your questions.</div>
      </mat-list-item>
      <mat-list-item [ngClass]="{
        'list-item-xsmall': isXSmall()
      }">
        <span matListItemIcon>
          <mat-icon color="primary">eco</mat-icon>
        </span>
        <div matListItemTitle class="list-item-title">If you can't find a feature you're looking for, please let us
          know.</div>
      </mat-list-item>
    </mat-list>
  </div>

  <div [ngClass]="{
    'container-item-card-xlarge': isXLarge(),
    'container-item-card-large': isLarge(),
    'container-item-card-medium': isMedium(),
    'container-item-card-small': isSmall(),
    'container-item-card-xsmall': isXSmall()
  }">
    <mat-card appearance="outlined" class="column-card mat-component-background mat-elevation-z2">
      <mat-card-header>
        <mat-card-title-group>
          <mat-card-title>How may we help and serve you?</mat-card-title>
          <mat-icon>menu_book</mat-icon>
        </mat-card-title-group>
      </mat-card-header>
      <div *ngIf="!isXSmall(); else xSmallContentImage">
        <img src="/assets/img/banners/small/product-banner-take-order-s.webp"
          alt="we-are-ready-to-help-you-contact-us" />
      </div>
      <mat-card-actions class="container-card-actions">
        <button mat-raised-button color="primary" [routerLink]="['/contact']">
          <mat-icon>mail</mat-icon>
          Contact Us
        </button>
      </mat-card-actions>
    </mat-card>
  </div>
</div>

<ng-template #xSmallContentImage>
  <div *ngIf="isXSmall();">
    <img src="/assets/img/banners/xsmall/product-banner-take-order-xs.webp" alt="we-are-ready-to-help-you-contact-us" />
  </div>
</ng-template>