import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, map, Observable, of, throwError } from 'rxjs';
import { environment } from 'src/environments/environment';
import { CreateUserModel } from '../models/create-user-model';
import { User } from '../models/user-model';
import { ValidationException } from '../core/exceptions/validation-exception';
import { UnknownErrorException } from '../core/exceptions/unknown-error-exception';

class CreateUserApiModel {
  firstName: string;
  lastName: string;
  email: string;
  password: string;
}

class UserApiModel {
  id: string;
}

@Injectable({
  providedIn: 'root'
})
export class RegistrationService {

  constructor(private http: HttpClient) { }

  createUser(createUserData: CreateUserModel) {
    const httpOptions = {
      headers: new HttpHeaders({
        ContentType: 'application/json',
      })
    };

    var createUserApiModel = new CreateUserApiModel();
    createUserApiModel.firstName = createUserData.firstName;
    createUserApiModel.lastName = createUserData.lastName;
    createUserApiModel.email = createUserData.email;
    createUserApiModel.password = createUserData.password;

    const url = environment.apiUrl + '/api/users';
    const result = this.http.post<UserApiModel>(url, createUserApiModel, httpOptions);
    return result.pipe(
      map(data => this.mapFromData(data)),
      catchError((response: HttpErrorResponse) => {
        if (response.status == 400) {
          if (response.error.violations) {
            const validationException = new ValidationException(response.error.violations);
            return throwError(() => validationException);
          }
          return throwError(() => new UnknownErrorException());
        }
        return throwError(() => this.handleError('createUser', null));
      })
    );
  }

  private mapFromData(data: UserApiModel): User {
    const user = new User();
    user.id = data.id;
    return user;
  }

  /**
   * Handle Http operation that failed.
   * Let the app continue.
   * @param operation - name of the operation that failed
   * @param result - optional value to return as the observable result
  */
  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      console.error(error); // log to console instead
      // Let the app keep running by returning an empty result.
      return of(result);
    };
  }
}
