
export const OptionVeganOptions = "Vegan Options";
export const OptionVegetarianOptions = "Vegetarian Options";
export const OptionGlutenFreeOptions = "Gluten Free Options";
export const OptionBioOrganic = "Bio Organic";
export const OptionBioDynamic = "Bio Dynamic";
export const OptionZeroWaste = "Zero Waste";
export const OptionPlasticFree = "Plastic Free";

export enum StoreLocationOption {
    VEGAN_OPTIONS = "VEGAN_OPTIONS",
    VEGETARIAN_OPTIONS = "VEGETARIAN_OPTIONS",
    GLUTEN_FREE_OPTIONS = "GLUTEN_FREE_OPTIONS",
    BIO_ORGANIC = "BIO_ORGANIC",
    BIO_DYNAMIC = "BIO_DYNAMIC",
    ZERO_WASTE = "ZERO_WASTE",
    PLASTIC_FREE = "PLASTIC_FREE"
}