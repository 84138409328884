<div class="container mat-component-background">
    <h1 mat-dialog-title>{{ data.title }}</h1>
    <div mat-dialog-content class="container-content">
        <app-repeat-rule [repeatRuleFormGroup]="recurrenceRuleFormGroup" [rrule]="recurrenceRule"></app-repeat-rule>
        <app-end-rule [endDateRuleFormGroup]="recurrenceRuleFormGroup" [rrule]="recurrenceRule"></app-end-rule> 
    </div>
    <div mat-dialog-actions fxLayout="row" fxLayoutAlign="end">
        <button mat-raised-button (click)="onCancelClick()">Cancel</button>
      <button
        mat-raised-button
        color="primary"
        (click)="onConfirmClick()"
        cdkFocusInitial
      >
        {{ data.confirm }}
      </button>
    </div>
  </div>
  