import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-checkout-failure',
  templateUrl: './checkout-failure.component.html',
  styleUrls: ['./checkout-failure.component.scss']
})
export class CheckoutFailureComponent implements OnInit {
  timeLeft: number = 5;
  timerInterval : any;

  constructor(private router : Router){}
  
  ngOnInit(): void {
    // Do nothing
    this.startTimer();
  }

  startTimer() : void {
    this.timerInterval = setInterval(() => {
      if (this.timeLeft > 0) {
        this.timeLeft--;
      } else {
        this.router.navigate(['/pricing']);
      }
    }, 1000)
  }

  navigateManually() : void {
    this.router.navigate(['/pricing']);
  }
}
