<div class="container">
  <mat-card appearance="outlined" class="mat-component-background mat-elevation-z2">
    <mat-card-header>
      <div mat-card-avatar class="{{ getSubscriptionPlanAvatarCssClass() }}"></div>
      <mat-card-title>
        {{ subscriptionPlanViewModel.title }}
      </mat-card-title>
      <mat-card-subtitle>
        {{ subscriptionPlanViewModel.description }}
      </mat-card-subtitle>
    </mat-card-header>
    <mat-card-content class="container-card-content">
      <div class="content-subscription-price">
        <h1>
          {{ priceViewModel.currency }}
          {{ priceViewModel.amount | number : "1.2-2"
          }}<small> / {{ priceViewModel.interval }}*</small>
        </h1>
        <div *ngIf="isAnnually()" class="content-subscription-save-annually">
          Save 16%
        </div>
      </div>
      <div class="content-amuse-bouche">
        <p>
          Amuse-Bouche<br /><em><small>one year free trial</small></em>
        </p>
      </div>
      <mat-divider style="width: 100px"></mat-divider>
      <div class="feature-content">
        <mat-divider inset="true"></mat-divider>
        <div *ngFor="
            let subscriptionFeatureViewModel of subscriptionFeatureViewModels
          " class="subscription-feature-content">
          <div>{{ subscriptionFeatureViewModel.title }}</div>
        </div>
        <mat-divider inset="true"></mat-divider>
        <div *ngFor="let supportFeatureViewModel of supportFeatureViewModels" class="support-feature-content">
          <div>{{ supportFeatureViewModel.title }}</div>
        </div>
      </div>
      <div *ngIf="isAnnually(); else subscriptionMonthlyBilled">
        <div class="content-subscription-billing">
          <mat-divider style="width: 100px"></mat-divider>
          <p>
            Billed Annually
            <br />
            <small><em>* VAT may apply</em></small>
          </p>
        </div>
      </div>
    </mat-card-content>
    <mat-card-actions class="container-card-actions">
      <div *ngIf="isAuthenticated && isCheckoutEnabled(); else noAuth">
        <button mat-raised-button color="primary" (click)="onSubmit()">
          Checkout
        </button>
      </div>
    </mat-card-actions>
  </mat-card>
</div>

<ng-template #noAuth>
  <div *ngIf="isCheckoutEnabled();">
    <button mat-raised-button color="primary" [routerLink]="['/register']">
      Start Today
    </button>
  </div>
</ng-template>

<ng-template #subscriptionMonthlyBilled>
  <div class="content-subscription-billing">
    <mat-divider style="width: 100px"></mat-divider>
    <p>
      Billed Monthly
      <br />
      <small><em>* VAT may apply</em></small>
    </p>
  </div>
</ng-template>