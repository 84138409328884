import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Subject, filter, takeUntil } from 'rxjs';
import { ConfirmationDialogComponent } from 'src/app/core/components/dialogs/confirmation-dialog/confirmation-dialog.component';
import { SpinnerDialogComponent } from 'src/app/core/components/dialogs/spinner-dialog/spinner-dialog.component';
import { MaximumActivePromotionsExceededException } from 'src/app/core/exceptions/maximum-active-promotions-exceeded-exception';
import { NoActiveSubscriptionException } from 'src/app/core/exceptions/no-active-subscription-exception';
import { ValidationException } from 'src/app/core/exceptions/validation-exception';
import { PromotionModel } from 'src/app/models/promotion-model';
import { UpdatePromotionModel } from 'src/app/models/update-promotion-model';
import { SpinnerService } from 'src/app/services/spinner.service';
import { DeletePromotionComponentStore } from 'src/app/stores/components/promotion-delete/delete-promotion.component-store';
import { PromotionDetailComponentStore } from 'src/app/stores/components/promotion-detail/promotion-detail.component-store';
import { UpdatePromotionComponentStore } from 'src/app/stores/components/promotion-update/update-promotion.component-store';
import { selectBusinessAccount } from 'src/app/stores/global/app.selectors';

@Component({
  selector: 'app-promotion-page',
  templateUrl: './promotion-page.component.html',
  styleUrls: ['./promotion-page.component.scss']
})
export class PromotionPageComponent implements OnInit {

  viewModel$ = this.promotionDetailComponentStore.viewModel$;
  editMode: boolean = false;

  companyId: string;

  ngUnsubscribeState = new Subject<void>();
  private promotion: PromotionModel;

  private loadingSpinnerDialogRef: MatDialogRef<SpinnerDialogComponent>;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private promotionDetailComponentStore: PromotionDetailComponentStore,
    private dialog: MatDialog,
    private deletePromotionComponentStore: DeletePromotionComponentStore,
    private updatePromotionComponentStore: UpdatePromotionComponentStore,
    private snackBar: MatSnackBar,
    private spinnerService: SpinnerService,
    private readonly store: Store
  ) { }

  ngOnInit(): void {
    this.route.params.subscribe(params => {
      this.store.select(selectBusinessAccount).pipe(filter(result => Boolean(result))).subscribe(businessAccount => {
        const gettingStartedCompanyDetails: boolean = !businessAccount.company.name || businessAccount.company.name === '';
        const gettingStartedCompanyLogo: boolean = !businessAccount.company.logo;
        if (gettingStartedCompanyDetails || gettingStartedCompanyLogo) {
          this.router.navigate(['/getting-started']);
        }
        else {
          this.companyId = params.companyId;
          this.showSpinner();
          this.promotionDetailComponentStore.getPromotion({ companyId: params.companyId, promotionId: params.promotionId });
        }
      });
    });

    this.viewModel$.pipe(takeUntil(this.ngUnsubscribeState)).subscribe(viewModel => {
      this.promotion = viewModel.promotion;
    });

    this.deletePromotionComponentStore.success$.pipe(takeUntil(this.ngUnsubscribeState)).subscribe((result) => {
      if (result) {
        this.resetComponentStores();
        this.snackBar.open("Promotion archived successfully", "Dismiss");
        this.router.navigate(['/promotions']);
      }
    });

    this.deletePromotionComponentStore.errorMessage$.pipe(takeUntil(this.ngUnsubscribeState)).subscribe((errorMessage) => {
      if (errorMessage !== null && errorMessage !== undefined) {
        this.resetComponentStores();
        if (errorMessage instanceof ValidationException) {
          const validationViolationMessage = errorMessage.violations.map(v => v.message).join("\n");
          this.snackBar.open(validationViolationMessage, "Dismiss");
        }
        else {
          this.snackBar.open(errorMessage, "Dismiss");
        }
      }
    });

    this.updatePromotionComponentStore.success$.pipe(takeUntil(this.ngUnsubscribeState)).subscribe((result) => {
      if (result) {
        this.resetComponentStores();
        this.snackBar.open("Promotion updated successfully", "Dismiss");
      }
    });

    this.updatePromotionComponentStore.errorMessage$.pipe(takeUntil(this.ngUnsubscribeState)).subscribe((errorMessage) => {
      if (errorMessage !== null && errorMessage !== undefined) {
        this.resetComponentStores();

        let displayErrorMessage = "Something went wrong.";
        if (errorMessage instanceof NoActiveSubscriptionException) {
          displayErrorMessage = 'No active subscriptionplan order. Please buy a subscriptionplan first.';
        }
        else if (errorMessage instanceof MaximumActivePromotionsExceededException) {
          displayErrorMessage = 'You have reached the maximum amount of active promotions with your current subscription. If you want more promotions, consider upgrading your subscriptionplan.';
        }
        if (errorMessage instanceof ValidationException) {
          const validationViolationMessage = errorMessage.violations.map(v => v.message).join("\n");
          this.snackBar.open(validationViolationMessage, "Dismiss");
        }
        else {
          this.snackBar.open(displayErrorMessage, "Dismiss");
        }

        this.snackBar.open(displayErrorMessage, "Dismiss");
      }
    });

    this.updatePromotionComponentStore.promotion$.pipe(takeUntil(this.ngUnsubscribeState), filter(result => Boolean(result))).subscribe((updatedPromotion) => {
      this.promotionDetailComponentStore.setInitial();
      this.promotionDetailComponentStore.getPromotion({companyId: updatedPromotion.company.id, promotionId: updatedPromotion.id});
  });

    this.promotionDetailComponentStore.loaded$.pipe(takeUntil(this.ngUnsubscribeState)).subscribe((loaded) => {
      this.hideSpinner(loaded);
    });

    this.updatePromotionComponentStore.loaded$.pipe(takeUntil(this.ngUnsubscribeState)).subscribe((loaded) => {
      this.hideSpinner(loaded);
    });

    this.deletePromotionComponentStore.loaded$.pipe(takeUntil(this.ngUnsubscribeState)).subscribe((loaded) => {
      this.hideSpinner(loaded);
    });
  }

  ngOnDestroy() {
    this.ngUnsubscribeState.next();
    this.ngUnsubscribeState.complete();
  }

  onDelete(event: any) {
    this.resetComponentStores();

    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      data: {
        title: "Are you sure?",
        message: "You want to archive this promotion",
        data: this.promotion.name,
        confirm: "Archive"
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.showSpinner();
        this.deletePromotionComponentStore.deleteCampaign({ companyId: this.promotion.company.id, promotionId: this.promotion.id });
      }
    });
  }

  onActivate(event: any) {
    this.resetComponentStores();
    this.showSpinner();
    const updatePromotion = new UpdatePromotionModel();
    updatePromotion.name = this.promotion.name;
    updatePromotion.description = this.promotion.description;
    updatePromotion.startDate = this.promotion.startDate;
    updatePromotion.endDate = this.promotion.endDate;
    updatePromotion.startDateVoucher = this.promotion.startDateVoucher;
    updatePromotion.endDateVoucher = this.promotion.endDateVoucher;
    updatePromotion.promotionRewardName = this.promotion.promotionReward.name;
    updatePromotion.promotionRewardDescription = this.promotion.promotionReward.description;
    updatePromotion.recurrencePattern = this.promotion.recurrencePattern;
    updatePromotion.promotionStatus = "ACTIVE";
    this.updatePromotionComponentStore.updatePromotion({ companyId: this.promotion.company.id, promotionId: this.promotion.id, updatePromotion: updatePromotion });
  }

  onDeactivate(event: any) {
    this.resetComponentStores();
    this.showSpinner();
    const updatePromotion = new UpdatePromotionModel();
    updatePromotion.name = this.promotion.name;
    updatePromotion.description = this.promotion.description;
    updatePromotion.startDate = this.promotion.startDate;
    updatePromotion.endDate = this.promotion.endDate;
    updatePromotion.startDateVoucher = this.promotion.startDateVoucher;
    updatePromotion.endDateVoucher = this.promotion.endDateVoucher;
    updatePromotion.promotionRewardName = this.promotion.promotionReward.name;
    updatePromotion.promotionRewardDescription = this.promotion.promotionReward.description;
    updatePromotion.recurrencePattern = this.promotion.recurrencePattern;
    updatePromotion.promotionStatus = "INACTIVE";
    this.updatePromotionComponentStore.updatePromotion({ companyId: this.promotion.company.id, promotionId: this.promotion.id, updatePromotion: updatePromotion });
  }

  onEditPressed(event : any){
    this.editMode = true;
  }

  onEditCancelPressed(event: any){
    this.editMode = false;
  }

  onUpdateSuccess(event : any){
    this.editMode = false;
  }
  
  private resetComponentStores() {
    this.deletePromotionComponentStore.setInitial();
    this.updatePromotionComponentStore.setInitial();
  }

  private showSpinner() {
    this.loadingSpinnerDialogRef = this.spinnerService.show();
  }

  private hideSpinner(loaded: boolean) {
    if (loaded && this.loadingSpinnerDialogRef !== null) {
      this.spinnerService.hide(this.loadingSpinnerDialogRef);
      this.loadingSpinnerDialogRef = null;
    }
  }
}
