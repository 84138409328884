<div class="container">
  <mat-card appearance="outlined" class="mat-component-background mat-elevation-z2">
    <mat-card-header>
      <div *ngIf="currentSubscriptionPlanOrder; else noActiveSubscriptionPlanOrderTitle">
        <mat-card-title>Current Order</mat-card-title>
    </div>
    </mat-card-header>
    <mat-card-content>
          <div *ngIf="currentSubscriptionPlanOrder">
            <div fxFlex fxLayout="column" fxLayoutAlign="start" class="container-column">
              <mat-form-field appearance="outline">
                <mat-label>Order Id</mat-label>
                <input
                  matInput
                  readonly
                  value="{{ currentSubscriptionPlanOrder?.id }}"
                />
              </mat-form-field>
              <mat-form-field appearance="outline">
                <mat-label>Subscription Plan</mat-label>
                <input
                  matInput
                  readonly
                  value="{{ currentSubscriptionPlanOrder?.subscriptionPlanName }}"
                />
              </mat-form-field>
              <mat-form-field appearance="outline">
                <mat-label>Description</mat-label>
                <input
                  matInput
                  readonly
                  value="{{ currentSubscriptionPlanOrder?.subscriptionPlanDescription }}"
                />
              </mat-form-field>
            </div>
          </div>
    </mat-card-content>
    <mat-card-actions>
      <div class="container-actions" fxLayout="row" fxLayoutAlign="start" fxLayoutGap="8px">
        <button
          mat-button
          (click)="goToPaymentProviderCustomerPortal()"
          color="primary"
        >
          <mat-icon>storefront</mat-icon><mat-icon>open_in_new</mat-icon>Upgrade Subscription Plan
        </button>
      </div>
    </mat-card-actions>
  </mat-card>
</div>

<ng-template #noActiveSubscriptionPlanOrderTitle>
  <mat-card-title>No Active Order</mat-card-title>
</ng-template>