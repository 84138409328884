<div class="container">
  <div *ngIf="showPricingBanner">
    <app-buy-subscription-plan-banner></app-buy-subscription-plan-banner>
  </div>
  <div *ngIf="unpaidInvoice$ | async">
    <app-unpaid-invoice-banner
      [unpaidInvoice]="unpaidInvoice$ | async"
    ></app-unpaid-invoice-banner>
  </div>
  <div class="container" fxLayout="row" fxLayoutAlign="start">
    <div *ngIf="readMode; else editMode">
      <div class="container-company" fxFlex>
        <app-company
          [businessAccount]="businessAccount$ | async"
          (onEditPressed)="onEdit($event)"
        ></app-company>
      </div>
    </div>
    <div class="container-company-logo">
      <app-company-logo
        [company]="(businessAccount$ | async)?.company"
      ></app-company-logo>
    </div>
  </div>
</div>

<ng-template #editMode>
  <div class="container-company" fxFlex>
    <app-company-edit
    [minNameLength]="minNameLength"
    [maxNameLength]="maxNameLength"
   [minDescriptionLength]="minDescriptionLength"
   [maxDescriptionLength]="maxDescriptionLength"
   [minEmailLength]="minEmailLength"
   [maxEmailLength]="maxEmailLength"
   [minUrlLength]="minUrlLength"
   [maxUrlLength]="maxUrlLength"

      [businessAccount]="businessAccount$ | async"
      [companyDetailsFormGroup]="companyDetailsFormGroup"
      (onCancelPressed)="onCancel($event)"
      (onSavePressed)="onSave($event)"
    ></app-company-edit>
  </div>
</ng-template>
