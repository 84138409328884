<div class="container">
  <div class="container-content">
    <div class="container-content-banner">
      <h1>How it works</h1>
      <h2>
        Planet VG empowers your business to create stronger connections
        with your customers, whether they are new or returning.
      </h2>
      <h3>
        Easily create promotions, stamp cards and redeem
        rewards with our climate-, animal- and user-friendly app.
      </h3>
    </div>
    <div 
      [ngClass]="{ 'container-content-features-xlarge': isXLarge(),
      'container-content-features-large': isLarge(),
      'container-content-features-medium': isMedium(),
      'container-content-features-small': isSmall(),
      'container-content-features-xsmall': isXSmall() }" >
      <div
        [ngClass]="{
          'container-content-features-side-xlarge': isXLarge(),
          'container-content-features-side-large': isLarge(),
          'container-content-features-side-medium': isMedium(),
          'container-content-features-side-small': isSmall(),
          'container-content-features-side-xsmall': isXSmall()
        }"
      >
        <h2>What is Planet VG?</h2>
        <h3>
          Planet VG puts your paper loyalty card onto your customer's phone as a
          digital card. Our easy-to-use loyalty app allows your customers to
          collect stamps. They can also grab one-off promos and redeem the reward
          vouchers.
        </h3>
      </div>
      <div
        [ngClass]="{
          'container-content-features-side-element-xlarge': isXLarge(),
          'container-content-features-side-element-large': isLarge(),
          'container-content-features-side-element-medium': isMedium(),
          'container-content-features-side-element-small': isSmall(),
          'container-content-features-side-element-xsmall': isXSmall()
        }"
      >
        <app-feature-grid></app-feature-grid>
      </div>
    </div>
    <div class="container-banner-content-enjoy">
      <h2>
        Become a partner, and you will receive an Amuse-Bouche (<em>a year free trial</em>). Enjoy, it's on
        the house!
      </h2>
    </div>
  </div>
</div>
