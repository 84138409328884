<div class="container">
  <div fxLayout="column" fxLayoutAlign="center center">
    <h1>Oh no, you didn't make it through.</h1>
    <div class="banner-content">
      <h2>Don't worry. We understand it, maybe try again later.</h2>
      <h2>
        You will be automatically redirected to the pricing page in
        {{ timeLeft }} seconds. <br/> 
        If that didn't happen, please click this button
        <button
          mat-raised-button
          color="primary"
          (click)="navigateManually()"
          cdkFocusInitial
        >
          Pricing
        </button>
      </h2>
      <h3>The Planet VG Chefs</h3>
    </div>
  </div>
</div>
