<div class="container">
  <mat-card appearance="outlined" class="mat-component-background mat-elevation-z2">
    <mat-card-header
      fxLayout="column"
      fxLayoutAlign="center center"
      class="header-content"
    >
    <mat-card-title>
        <h1>Sign In</h1>
    </mat-card-title>
    </mat-card-header>
    <mat-card-content>
        <div fxLayout="row" fxLayoutAlign="center">
            {{ content }}
        </div>
    </mat-card-content>
    <mat-card-actions>
        <div fxLayout="row" fxLayoutAlign="end">
        <button mat-raised-button (click)="signIn()">
            <mat-icon><span class="material-icons">login</span></mat-icon
            >Sign In
          </button>
        </div>
    </mat-card-actions>
  </mat-card>
  
</div>
