<div class="container">
  <div fxLayout="column" fxLayoutAlign="center center">
    <h1>Thank you!</h1>
    <div class="banner-content">
      <h2>Let's start saving the planet.</h2>
    </div>
    <div>
      <p>You will be automatically redirected to the dashboard in {{ timeLeft }} seconds.</p>
    </div>
    <div>
      <p>The Planet VG Team</p>
    </div>
    <div *ngIf="showNavigateButton$ | async">
      <div>
        If that didn't happen, please click this button<br>
        <button
          mat-raised-button
          color="primary"
          (click)="navigateManually()"
          cdkFocusInitial
        >
          Dashboard
        </button>
      </div>
    </div>
  </div>
</div>
