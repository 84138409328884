import { Component } from '@angular/core';
import { Store } from '@ngrx/store';
import { businessAccountGetAction, unpaidInvoiceGetAction } from './stores/global/app.actions';
import { AuthorizationGuardService } from './services/authorization-guard.service';
import { PlanetVGJwtClaimNames } from './auth/planetvg-jwt-claim-names.model';
import { selectUnpaidInvoice } from './stores/global/app.selectors';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'planetvg-business-portal';
  unpaidInvoice$ = this.store.select(selectUnpaidInvoice);

  constructor(private authorizationService: AuthorizationGuardService, private readonly store: Store) {
  }

  ngOnInit() {
    this.authorizationService.checkAuth().pipe(
    )
      .subscribe(({ isAuthenticated, userData, accessToken, idToken }) => {
        console.log(accessToken);
        if (isAuthenticated) {
          if ((PlanetVGJwtClaimNames.BusinessAccountId in userData)) {
            this.authorizationService.businessAccountId = userData[PlanetVGJwtClaimNames.BusinessAccountId];
            this.store.dispatch(businessAccountGetAction());
            this.store.dispatch(unpaidInvoiceGetAction({companyId: userData[PlanetVGJwtClaimNames.CompanyId]}))
          }
        }
        else {
          this.authorizationService.reset();
        }
      });
  }

  scrollTop(event) {
    window.scroll(0,0);
  }
}
