<div [ngClass]="{
  'container-xlarge': isXLarge(),
  'container-large': isLarge(),
  'container-medium': isMedium(),
  'container-small': isSmall(),
  'container-xsmall': isXSmall()
}">
  <div [ngClass]="{
    'container-content-xlarge': isXLarge(),
    'container-content-large': isLarge(),
    'container-content-medium': isMedium(),
    'container-content-small': isSmall(),
    'container-content-xsmall': isXSmall()
  }">
    <div [ngClass]="{
      'content-xlarge': isXLarge(),
      'content-large': isLarge(),
      'content-medium': isMedium(),
      'content-small': isSmall(),
      'content-xsmall': isXSmall()
    }">
      <h1>Planet VG is Haute Cuisine</h1>
      <h2>
        Grow your business sustainably with a modern, intuitive climate- and animal-friendly experience designed for your whole team.
      </h2>
      <button mat-raised-button color="primary" routerLink="/pricing">
        Check our Menu
      </button>
    </div>
  </div>
</div>
