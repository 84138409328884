import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { StoreLocationCategory, CategoryBodyPieringShop, CategoryCafeCoffeeAndTeaHouse, CategoryCafeteria, CategoryDryCleaner, CategoryJuiceBar, CategoryFarmersMarket, CategoryFastFoodRestaurant, CategoryFitnessCenter, CategoryFoodStand, CategoryFoodTruck, CategoryFruitAndVegetableStore, CategoryGroceryStore, CategoryHairSalon, CategoryHealthFoodStore, CategoryHotel, CategoryIceCreamParlor, CategoryMassageClinic, CategoryNailSalon, CategoryPetService, CategoryRestaurant, CategoryRetailStore, CategorySkinCareClinic, CategorySpa, CategoryTattooParlor, CategoryYogaStudio, CategoryBagelShop, CategoryBakery, CategoryBar, CategoryBarberShop } from 'src/app/models/store-location-category-model';
import { StoreLocationModel } from 'src/app/models/store-location-model';
import { StoreLocationOption } from 'src/app/models/store-location-option-model';
import { StoreLocationType, TypeOther, TypeVegan, TypeVegetarian } from 'src/app/models/store-location-type-model';

interface StoreLocationTypeViewModel {
  value: StoreLocationType,
  viewValue: string,
}

interface StoreLocationCategoryViewModel {
  value: StoreLocationCategory,
  viewValue: string,
}

@Component({
  selector: 'app-store-location-edit',
  templateUrl: './store-location-edit.component.html',
  styleUrls: ['./store-location-edit.component.scss']
})
export class StoreLocationEditComponent implements OnInit {
  public storeLocationTypes: StoreLocationTypeViewModel[] = [
    { value: StoreLocationType.VEGAN, viewValue: TypeVegan },
    { value: StoreLocationType.VEGETARIAN, viewValue: TypeVegetarian },
    { value: StoreLocationType.OTHER, viewValue: TypeOther },
  ];

  public storeLocationCategories: StoreLocationCategoryViewModel[] = [
    { value: StoreLocationCategory.BAGEL_SHOP, viewValue: CategoryBagelShop },
    { value: StoreLocationCategory.BAKERY, viewValue: CategoryBakery },
    { value: StoreLocationCategory.BAR, viewValue: CategoryBar },
    { value: StoreLocationCategory.BARBER_SHOP, viewValue: CategoryBarberShop },
    { value: StoreLocationCategory.BODY_PIERCING_SHOP, viewValue: CategoryBodyPieringShop },
    { value: StoreLocationCategory.CAFE_COFFEE_AND_TEA_HOUSE, viewValue: CategoryCafeCoffeeAndTeaHouse },
    { value: StoreLocationCategory.CAFETERIA, viewValue: CategoryCafeteria },
    { value: StoreLocationCategory.DRY_CLEANER, viewValue: CategoryDryCleaner },
    { value: StoreLocationCategory.JUICE_BAR, viewValue: CategoryJuiceBar },
    { value: StoreLocationCategory.FARMERS_MARKET, viewValue: CategoryFarmersMarket },
    { value: StoreLocationCategory.FAST_FOOD_RESTAURANT, viewValue: CategoryFastFoodRestaurant },
    { value: StoreLocationCategory.FITNESS_CENTER, viewValue: CategoryFitnessCenter },
    { value: StoreLocationCategory.FOOD_STAND, viewValue: CategoryFoodStand },
    { value: StoreLocationCategory.FOOD_TRUCK, viewValue: CategoryFoodTruck },
    { value: StoreLocationCategory.FRUIT_AND_VEGETABLE_STORE, viewValue: CategoryFruitAndVegetableStore },
    { value: StoreLocationCategory.GROCERY_STORE, viewValue: CategoryGroceryStore },
    { value: StoreLocationCategory.HAIR_SALON, viewValue: CategoryHairSalon },
    { value: StoreLocationCategory.HEALTH_FOOD_STORE, viewValue: CategoryHealthFoodStore },
    { value: StoreLocationCategory.HOTEL, viewValue: CategoryHotel },
    { value: StoreLocationCategory.ICE_CREAM_PARLOR, viewValue: CategoryIceCreamParlor },
    { value: StoreLocationCategory.MASSAGE_CLINIC, viewValue: CategoryMassageClinic },
    { value: StoreLocationCategory.NAIL_SALON, viewValue: CategoryNailSalon },
    { value: StoreLocationCategory.PET_SERVICE, viewValue: CategoryPetService },
    { value: StoreLocationCategory.RESTAURANT, viewValue: CategoryRestaurant },
    { value: StoreLocationCategory.RETAIL_STORE, viewValue: CategoryRetailStore },
    { value: StoreLocationCategory.SKIN_CARE_CLINIC, viewValue: CategorySkinCareClinic },
    { value: StoreLocationCategory.SPA, viewValue: CategorySpa },
    { value: StoreLocationCategory.TATTOO_PARLOR, viewValue: CategoryTattooParlor },
    { value: StoreLocationCategory.YOGA_STUDIO, viewValue: CategoryYogaStudio },
  ];

  @Input()
  storeLocation: StoreLocationModel;

  @Input()
  storeLocationDetailsFormGroup: UntypedFormGroup;

  @Input()
  minNameLength: number;
  @Input()
  maxNameLength: number;
  @Input()
  minDescriptionLength: number;
  @Input()
  maxDescriptionLength: number;
  @Input()
  minAddressLineLength : number;
  @Input()
  maxAddressLineLength : number;
  @Input()
  minPostalCodeLength : number;
  @Input()
  maxPostalCodeLength : number;
  @Input()
  minCityLength : number;
  @Input()
  maxCityLength : number;
  @Input()
  minPhoneNumberLength : number;
  @Input()
  maxPhoneNumberLength : number;

  @Output()
  onCancelPressed = new EventEmitter();

  @Output()
  onSavePressed = new EventEmitter();

  ngOnInit(): void {
    if(this.storeLocation !== null){
      this.storeLocationDetailsFormGroup.patchValue({'id': this.storeLocation.id});
      this.storeLocationDetailsFormGroup.patchValue({'name': this.storeLocation.name});
      this.storeLocationDetailsFormGroup.patchValue({'description': this.storeLocation.description});
      this.storeLocationDetailsFormGroup.patchValue({'phoneNumber': this.storeLocation.phoneNumber});
      this.storeLocationDetailsFormGroup.patchValue({'addressLine1': this.storeLocation.addressLine1});
      this.storeLocationDetailsFormGroup.patchValue({'addressLine2': this.storeLocation.addressLine2});
      this.storeLocationDetailsFormGroup.patchValue({'postalCode': this.storeLocation.postalCode});
      this.storeLocationDetailsFormGroup.patchValue({'city': this.storeLocation.city});
      this.storeLocationDetailsFormGroup.patchValue({'country': this.storeLocation.country});
      this.storeLocationDetailsFormGroup.patchValue({'storeLocationStatus': this.storeLocation.storeLocationStatus});
      this.storeLocationDetailsFormGroup.patchValue({'type': this.storeLocation.storeLocationType});
      this.storeLocationDetailsFormGroup.patchValue({'category': this.storeLocation.storeLocationCategory});
      this.storeLocationDetailsFormGroup.patchValue({'options': this._getFormGroupStoreLocationOptions()});
    }
  }

  cancel() {
    this.onCancelPressed.emit(null);
  }

  save() {
    this.onSavePressed.emit(null);
  }

  _getFormGroupStoreLocationOptions(): StoreLocationOption[] {
    let storeLocationOptions : Array<StoreLocationOption> = [];
    
    if (this.storeLocationDetailsFormGroup.value.storeLocationOptionVeganOptions) {
      storeLocationOptions.push(StoreLocationOption.VEGAN_OPTIONS);
    }
    if (this.storeLocationDetailsFormGroup.value.storeLocationOptionVegetarianOptions) {
      storeLocationOptions.push(StoreLocationOption.VEGETARIAN_OPTIONS);
    }
    if (this.storeLocationDetailsFormGroup.value.storeLocationOptionGlutenFreeOptions) {
      storeLocationOptions.push(StoreLocationOption.GLUTEN_FREE_OPTIONS);
    }
    if (this.storeLocationDetailsFormGroup.value.storeLocationOptionBioOrganic) {
      storeLocationOptions.push(StoreLocationOption.BIO_ORGANIC);
    }
    if (this.storeLocationDetailsFormGroup.value.storeLocationOptionBioDynamic) {
      storeLocationOptions.push(StoreLocationOption.BIO_DYNAMIC);
    }
    if (this.storeLocationDetailsFormGroup.value.storeLocationOptionZeroWaste) {
      storeLocationOptions.push(StoreLocationOption.ZERO_WASTE);
    }
    if (this.storeLocationDetailsFormGroup.value.storeLocationOptionPlasticFree) {
      storeLocationOptions.push(StoreLocationOption.PLASTIC_FREE);
    }
    return storeLocationOptions;
  }
}
