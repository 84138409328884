import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';


@Component({
  selector: 'app-signin',
  templateUrl: './signin.component.html',
  styleUrls: ['./signin.component.scss']
})
export class SigninComponent implements OnInit {
  @Input()
  public content : string;
  
  @Output()
  public onSignInPressed = new EventEmitter();

  constructor() { 
    // Do nothing
  }

  ngOnInit(): void {
    // Do nothing
  }

  signIn(){
    this.onSignInPressed.emit(null);
  }
}
