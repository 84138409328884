<div class="container">
  <mat-card appearance="outlined" class="mat-component-background mat-elevation-z2">
    <mat-card-content>
      <div>
        <div class="container-logo">
          <ng-container *ngIf="isImageSaved; else elseTemplate">
            <img [src]="(companyLogoUrl$ | async)" class="logo" alt="company-logo" />
          </ng-container>
          <ng-template #elseTemplate>
            <img [src]="placeholderUrl" class="logo" alt="company-logo"/>
          </ng-template>
        </div>
      </div>
    </mat-card-content>
    <mat-card-actions>
      <div class="container-actions" fxLayout="row" fxLayoutAlign="end">
        <div>
            <button mat-icon-button (click)="removeImage()" *ngIf="isImageSaved">
                <mat-icon>delete</mat-icon>
              </button>
        </div>
        <div>
            <button type="button" mat-icon-button (click)="fileInput.click()"><mat-icon>upload</mat-icon></button>
            <input hidden (change)="fileChangeEvent($event)" #fileInput type="file" id="file" accept=".jpg,.jpeg,.png">
        </div>
      </div>
    </mat-card-actions>
  </mat-card>
</div>
