<div class="container">
  <mat-card
    appearance="outlined"
    class="mat-component-background mat-elevation-z2"
  >
    <mat-card-header>
      <mat-card-title> Stamp Card Details </mat-card-title>
    </mat-card-header>
    <mat-card-content>
      <div fxLayout="row">
        <div fxFlex fxLayout="column" class="container-column">
          <mat-form-field appearance="outline">
            <mat-label>Name</mat-label>
            <input matInput readonly value="{{ campaign?.name }}" />
          </mat-form-field>
          <mat-form-field appearance="outline">
            <mat-label>Description</mat-label>
            <input matInput readonly value="{{ campaign?.description }}" />
          </mat-form-field>
          <mat-form-field appearance="outline">
            <mat-label>Start Date</mat-label>
            <input
              matInput
              readonly
              value="{{ campaign?.startDate | date : 'EEEE, MMMM d, y, HH:mm' }}"
            />
          </mat-form-field>
          <mat-form-field appearance="outline">
            <mat-label>End Date</mat-label>
            <input
              matInput
              readonly
              value="{{ campaign?.endDate | date : 'EEEE, MMMM d, y, HH:mm' }}"
            />
          </mat-form-field>
          <mat-form-field appearance="outline">
            <mat-label>Recurrence</mat-label>
            <input matInput readonly value="{{ recurrenceDisplayString }}" />
          </mat-form-field>
        </div>
        <div fxFlex fxLayout="column" class="container-column">
          <mat-form-field appearance="outline">
            <mat-label>Stamps Objective</mat-label>
            <input matInput readonly value="{{ campaign?.stampsObjective }}" />
          </mat-form-field>
          <mat-form-field appearance="outline">
            <mat-label>Reward Title</mat-label>
            <input
              matInput
              readonly
              value="{{ campaign?.campaignReward.name }}"
            />
          </mat-form-field>
          <mat-form-field appearance="outline">
            <mat-label>Reward Description</mat-label>
            <textarea
              matInput
              readonly
              cdkTextareaAutosize
              cdkAutosizeMinRows="1"
              cdkAutosizeMaxRows="5"
              value="{{ campaign?.campaignReward.description }}"
            ></textarea>
          </mat-form-field>
          <mat-form-field appearance="outline">
            <mat-label>Voucher Expiration</mat-label>
            <input
              matInput
              readonly
              value="{{ campaign?.toVoucherDurationDisplayString() }}"
            />
          </mat-form-field>
          <mat-form-field appearance="outline">
            <mat-label>Status</mat-label>
            <input matInput readonly value="{{ campaign?.campaignStatus }}" />
          </mat-form-field>
        </div>
      </div>
    </mat-card-content>
    <mat-card-actions>
      <div class="container-actions" fxLayout="row" fxLayoutAlign="end">
        <div *ngIf="canShowEditAction()">
          <button mat-icon-button (click)="edit()">
            <mat-icon>edit</mat-icon>
          </button>
        </div>
        <div *ngIf="canShowArchiveAction()">
          <button mat-icon-button (click)="delete()">
            <mat-icon>archive</mat-icon>
          </button>
        </div>
        <div *ngIf="canShowActivateAction()">
          <button
            mat-icon-button
            (click)="activate()"
            matTooltip="Activate the campaign"
          >
            <mat-icon>play_circle</mat-icon>
          </button>
        </div>
      </div>
    </mat-card-actions>
  </mat-card>
</div>
