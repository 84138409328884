<div class="container">
    <mat-card appearance="outlined" class="mat-component-background mat-elevation-z2">
      <mat-card-header>
        <mat-card-title> Store User Details </mat-card-title>
      </mat-card-header>
      <mat-card-content>
        <div fxLayout="row">
          <div class="container-column" fxLayout="column">
            <mat-form-field appearance="outline">
              <mat-label>Username</mat-label>
              <input matInput readonly value="{{ storeUser.username }}" />
            </mat-form-field>
          </div>
         </div>
      </mat-card-content>
      <mat-card-actions>
        <div class="container-actions" fxLayout="row" fxLayoutAlign="end">
          <button 
            mat-icon-button 
            matTooltip="Generate new store user password"
            (click)="generateNewPassword()">
            <mat-icon>lock_reset</mat-icon>
          </button>
        </div>
      </mat-card-actions>
    </mat-card>
  </div>
  