import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import * as _ from 'lodash';
import { SubscriptionPlanModel } from 'src/app/models/subscription-plan-model';
import { CheckoutFormComponentStore } from 'src/app/stores/components/checkout/checkout.component-store';
import { PaymentRequestModel } from 'src/app/models/payment-request-model';
import { SpinnerService } from 'src/app/services/spinner.service';
import { Subject, takeUntil } from 'rxjs';
import { MatDialogRef } from '@angular/material/dialog';
import { SpinnerDialogComponent } from '../dialogs/spinner-dialog/spinner-dialog.component';
import { environment } from 'src/environments/environment';

export class SubscriptionPlanViewModel {
  id : string;
  title : string;
  description : string;

  constructor(id:string, title: string, description: string){
    this.id = id;
    this.title = title;
    this.description = description;
  }
}

export class PriceViewModel {
  id : string;
  interval: string
  amount: string;
  currency: string;
  recurringTye: string;

  constructor(id:string, amount : number, currency: string, interval: string){
    this.id = id;
    this.amount = (amount / 100.0).toString();
    this.currency = currency == "eur" ? "€" : "$";
    this.interval = interval;
  }
}

export class FeatureViewModel {
  title: string
  displayOrder : number;

  constructor(name: string, value: string, displayOrder : number){
    if(value == '2147483647'){
      value = 'Unlimited';
    }
    else if(value == 'YES'){
      value = '';
    }
    
    this.title = value + ' ' + name;
    this.displayOrder = displayOrder;
  }
}


@Component({
  selector: 'app-pricing-subscription-plan',
  templateUrl: './pricing-subscription-plan.component.html',
  styleUrls: ['./pricing-subscription-plan.component.scss']
})
export class PricingSubscriptionPlanComponent implements OnInit {
  @Input()
  subscriptionPlanType : string;

  @Input()
  subscriptionPlan : SubscriptionPlanModel;

  @Input()
  recurringType : string;

  enabledCheckout: boolean = environment.enabledCheckout;
  isAuthenticated : boolean;
  subscriptionPlanViewModel : SubscriptionPlanViewModel;
  priceViewModel : PriceViewModel;

  supportFeatureViewModels = [];
  subscriptionFeatureViewModels = [];

  ngUnsubscribeState = new Subject<void>();
  private loadingSpinnerDialogRef : MatDialogRef<SpinnerDialogComponent>;

  constructor(private oidcSecurityService : OidcSecurityService,
    private checkoutComponentStore : CheckoutFormComponentStore,
    private spinnerService: SpinnerService,
    ) { }

  ngOnInit(): void {
    this.subscriptionPlanViewModel = this.getSubscriptionPlanViewModel();
    this.priceViewModel = this.getPrice();
    this.subscriptionFeatureViewModels = this.getSubscriptionFeatureViewModels();

    this.oidcSecurityService.isAuthenticated$.subscribe(({ isAuthenticated }) => {
     this.isAuthenticated = isAuthenticated;
    });

    this.checkoutComponentStore.loaded$.pipe(takeUntil(this.ngUnsubscribeState)).subscribe((loaded) => {
      this.hideSpinner(loaded);
    });
  }

  ngOnDestroy() {
    this.ngUnsubscribeState.next();
    this.ngUnsubscribeState.complete();
  }

  ngOnChanges(changes: SimpleChanges){
    if(changes.recurringType.currentValue){
      this.priceViewModel = this.getPrice();
    }
  }

  async onSubmit(){
    this.showSpinner();
    const paymentRequest = new PaymentRequestModel();
    paymentRequest.priceId = this.priceViewModel.id.trim();
    this.checkoutComponentStore.doCheckout({paymentRequestData: paymentRequest});
  }

  public getSubscriptionPlanAvatarCssClass() : string {
    if(this.subscriptionPlan.description.toUpperCase().startsWith("BASIC")){
      return "subscription-plan-sheep";
    }
    if(this.subscriptionPlan.description.toUpperCase().startsWith("STANDARD")){
      return "subscription-plan-pig";
    }
    if(this.subscriptionPlan.description.toUpperCase().startsWith("PREMIUM")){
      return "subscription-plan-cow";
    }
    return "subscription-plan-pig";
  }

  public isAnnually() : boolean {
    return this.recurringType.toUpperCase() != 'MONTHLY';
  }

  public isCheckoutEnabled() : boolean {
    return this.enabledCheckout;
  }

  private getPrice() : PriceViewModel {
    if(this.recurringType.toUpperCase() == 'MONTHLY'){
      return this.getMonthlyPrice();
    }
    return this.getAnnualPrice();
  }

  private getMonthlyPrice() : PriceViewModel {
    const result = _.filter(this.subscriptionPlan.prices, function(price){
      return price.recurring.interval == "month";
    });

    return new PriceViewModel(result[0].externalId, result[0].amount, result[0].currency, result[0].recurring.interval);
  }

  private getAnnualPrice() : PriceViewModel {
    const result = _.filter(this.subscriptionPlan.prices, function(price){
      return price.recurring.interval == "year";
    });

    return new PriceViewModel(result[0].externalId, result[0].amount, result[0].currency, result[0].recurring.interval);
  }

  private getSubscriptionPlanViewModel() : SubscriptionPlanViewModel {
    return new SubscriptionPlanViewModel(this.subscriptionPlan.externalId, this.subscriptionPlan.name, this.subscriptionPlan.description);
  }

  private getSubscriptionFeatureViewModels() : FeatureViewModel[] {
    let featureViewModels = this.subscriptionPlan.features.map(feature => new FeatureViewModel(feature.name, feature.featureValue, feature.displayOrder));
    featureViewModels.sort((a, b) => {      
      if(a.displayOrder < b.displayOrder){
        return -1;
      }
      if(a.displayOrder > b.displayOrder){
        return 1;
      }
      return 0;
    });
    return featureViewModels;
  }

  private showSpinner(){
    this.loadingSpinnerDialogRef = this.spinnerService.show();
  }

  private hideSpinner(loaded : boolean){
    if(loaded && this.loadingSpinnerDialogRef !== null){
      this.spinnerService.hide(this.loadingSpinnerDialogRef);
      this.loadingSpinnerDialogRef = null;
    }
  }
}
