<div class="container">
  <div [ngClass]="{
    'container-item-xlarge': isXLarge(),
    'container-item-large': isLarge(),
    'container-item-medium': isMedium(),
    'container-item-small': isSmall(),
    'container-item-xsmall': isXSmall()
  }">
    <h1>Advertise</h1>
    <h2>Promote your new menu, products or other services to your customers, whether they are new or existing.</h2>
    <div >
      <mat-list>
        <mat-list-item [ngClass]="{
          'list-item-xsmall': isXSmall()
        }">
          <span matListItemIcon>
            <mat-icon color="primary">eco</mat-icon>
          </span>
          <div matListItemTitle class="list-item-title">Give away vouchers to new customers or directly to your members.</div>
        </mat-list-item>
        <mat-list-item [ngClass]="{
          'list-item-xsmall': isXSmall()
        }">
          <span matListItemIcon>
            <mat-icon color="primary">eco</mat-icon>
          </span>
          <div matListItemTitle class="list-item-title">Create a recurring promotion easily.</div>
        </mat-list-item>
        <mat-list-item [ngClass]="{
          'list-item-xsmall': isXSmall()
        }">
          <span matListItemIcon>
            <mat-icon color="primary">eco</mat-icon>
          </span>
          <div matListItemTitle class="list-item-title">Customers can use the promotional voucher only once.</div>
        </mat-list-item>
      </mat-list>
    </div>
  </div>

  <div [ngClass]="{
    'container-item-card-xlarge': isXLarge(),
    'container-item-card-large': isLarge(),
    'container-item-card-medium': isMedium(),
    'container-item-card-small': isSmall(),
    'container-item-card-xsmall': isXSmall()
  }">
    <mat-card appearance="outlined" class="mat-component-background mat-elevation-z2">
      <mat-card-header>
        <div mat-card-avatar class="breakfast-avatar"></div>
        <mat-card-title>Promotions</mat-card-title>
        <mat-card-subtitle>Serving Breakfast : 7h - 12h </mat-card-subtitle>
      </mat-card-header>
      <div *ngIf="!isXSmall(); else xSmallContentImage">
        <img src="/assets/img/banners/small/product-banner-breakfast-s.webp" alt="breakfast-bring-in-new-customers" />
      </div>
      <mat-card-actions></mat-card-actions>
    </mat-card>
  </div>
</div>

<ng-template #xSmallContentImage>
  <div *ngIf="isXSmall();">
    <img src="/assets/img/banners/xsmall/product-banner-breakfast-xs.webp" alt="breakfast-bring-in-new-customers" />
  </div>
</ng-template>