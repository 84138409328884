import { Component, OnInit } from '@angular/core';
import { OidcSecurityService } from 'angular-auth-oidc-client';

@Component({
  selector: 'app-registered',
  templateUrl: './registered.component.html',
  styleUrls: ['./registered.component.scss']
})
export class RegisteredComponent implements OnInit {
  public signInContent = "Thank you for registering. Please log into the portal, so we can set up your organization.";
  
  constructor(private oidcSecurityService: OidcSecurityService) { }

  ngOnInit(): void {
    // Do nothing
  }

  signIn() {
    this.oidcSecurityService.authorize();
  }
}
