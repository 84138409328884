<div class="container mat-component-background">
    <h1 mat-dialog-title>{{ data.title }}</h1>
    <div mat-dialog-content>
        <p>
            {{ data.message }}</p>
        <br />
        <p>
            <strong>{{ data.data }}</strong>
        </p>
        
    </div>
    <div mat-dialog-actions fxLayout="row" fxLayoutAlign="space-between center">
        <button mat-raised-button color="secondary" [cdkCopyToClipboard]="data.data">
            <mat-icon>content_copy</mat-icon>
            Copy Password
        </button>
        <button mat-raised-button color="primary" (click)="onConfirmClick()" cdkFocusInitial>
            {{ data.confirm }}
        </button>
    </div>
</div>