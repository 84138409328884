<div [ngClass]="{
  'container-xlarge': isXLarge(),
  'container-large': isLarge(),
  'container-medium': isMedium(),
  'container-small': isSmall(),
  'container-xsmall': isXSmall()
}">
  <div [ngClass]="{
    'container-content-xlarge': isXLarge(),
    'container-content-large': isLarge(),
    'container-content-medium': isMedium(),
    'container-content-small': isSmall(),
    'container-content-xsmall': isXSmall()
  }">
    <div [ngClass]="{
      'content-xlarge': isXLarge(),
      'content-large': isLarge(),
      'content-medium': isMedium(),
      'content-small': isSmall(),
      'content-xsmall': isXSmall()
    }" >
        <h1>Prepared With Love</h1>
        <h2>For Climate Conscious and Vegan Businesses</h2>
    </div>
    <div [ngClass]="{
      'row-content-xlarge': isXLarge(),
      'row-content-large': isLarge(),
      'row-content-medium': isMedium(),
      'row-content-small': isSmall(),
      'row-content-xsmall': isXSmall()
    }"
    
  >
  <div 
  [ngClass]="{
    'row-element-xlarge': isXLarge(),
    'row-element-large': isLarge(),
    'row-element-medium': isMedium(),
    'row-element-small': isSmall(),
    'row-element-xsmall': isXSmall()
  }"
  >
    <app-product-banner-prepared-level-up></app-product-banner-prepared-level-up>
  </div>
  <div
  [ngClass]="{
    'row-element-xlarge': isXLarge(),
    'row-element-large': isLarge(),
    'row-element-medium': isMedium(),
    'row-element-small': isSmall(),
    'row-element-xsmall': isXSmall()
  }" 
  >
    <app-product-banner-prepared-build-connections></app-product-banner-prepared-build-connections>
  </div>
  <div
  [ngClass]="{
    'row-element-xlarge': isXLarge(),
    'row-element-large': isLarge(),
    'row-element-medium': isMedium(),
    'row-element-small': isSmall(),
    'row-element-xsmall': isXSmall()
  }" 
  >
    <app-product-banner-prepared-kyc></app-product-banner-prepared-kyc>
  </div>
</div>
  </div>
</div>


