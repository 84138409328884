import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Component, OnInit } from '@angular/core';

export interface ExplanationStep {
  index: number;
  title: string;
  subtitle: string;
  description: string;
  imageUrl: string;
  showPricingAction: boolean;
  forBusiness : boolean;
}

const EXPLANATION_STEPS : ExplanationStep[] = [
  // For your Customers
  { forBusiness: false, index: 0, title: "Starters", subtitle: "Download the Planet VG app", description: "Your customers download the Planet VG app, create an account, and become a member of your business.", showPricingAction: false, imageUrl:"/assets/img/how-it-works/how-it-works-customers-starters.webp" },
  { forBusiness: false, index: 1, title: "Mains", subtitle: "Claim Vouchers and Collect Stamps", description: "Customers can claim vouchers or receive a voucher when they have a full stamp card. They can redeem their vouchers the next time they visit your business.", showPricingAction: false, imageUrl:"/assets/img/how-it-works/how-it-works-customers-mains.webp" },
  { forBusiness: false, index: 2, title: "Desserts", subtitle: "Redeem Rewards", description: "Your customers feel appreciated when they are rewarded for their loyalty and support.", showPricingAction: true, imageUrl:"/assets/img/how-it-works/how-it-works-customers-desserts.webp" },
  // For your Business
  { forBusiness: true, index: 0, title: "Starters", subtitle: "Become a Partner", description: "Become a partner and receive an amuse-bouche, a one year free trial. Download the Planet VG Store App, while we prepare your order. Get ready to boost your sales.", showPricingAction: false, imageUrl:"/assets/img/how-it-works/how-it-works-business-starters.webp" },
  { forBusiness: true, index: 1, title: "Mains", subtitle: "Create Promotions and Stamp Cards", description: "Login to the portal and start creating promotions or stamp cards. Make sure you have a good-looking photo at hand to whet your customers' appetite for your offers.", showPricingAction: false, imageUrl:"/assets/img/how-it-works/how-it-works-business-mains.webp" },
  { forBusiness: true, index: 2, title: "Desserts", subtitle: "Start Retaining More Customers", description: "Once you're set up and you’re familiar with how it works, it’s time to let your customers know. Our partner success chefs will be available to assist you in setting up shop, so you can focus on what you do best.", showPricingAction: true, imageUrl:"/assets/img/how-it-works/how-it-works-business-desserts.webp" },
];

@Component({
  selector: 'app-how-does-it-work',
  templateUrl: './how-does-it-work.component.html',
  styleUrls: ['./how-does-it-work.component.scss']
})
export class HowDoesItWorkComponent implements OnInit {
  selectedExplanationType: string = "For your Customers";
  explanationTypes : string[] = ['For your Customers', 'For your Business'];
  
  private _isLarge: boolean
  private _isMedium: boolean;
  private _isSmall: boolean;
  private _isXSmall : boolean;

  constructor(private responsive: BreakpointObserver) {
    // Do nothing
  }

  ngOnInit(): void {
    // Do nothing
    this.responsive.observe([Breakpoints.XSmall, Breakpoints.Small, Breakpoints.Medium, Breakpoints.Large]).subscribe(
      result => {
        const breakpoints = result.breakpoints;

        this._isLarge = false;
        this._isMedium = false;
        this._isSmall = false;
        this._isXSmall = false;

        if (breakpoints[Breakpoints.Medium]) {
          this._isMedium = true;
        }
        else if (breakpoints[Breakpoints.Small]) {
          this._isSmall = true;
        }
        else if (breakpoints[Breakpoints.XSmall]) {
          this._isXSmall = true;
        }
        else {
          this._isLarge = true;
        }
      }
    );
  }

  getExplanationSteps() : ExplanationStep[] {
    const isForBusiness = this.selectedExplanationType === 'For your Business';
    return EXPLANATION_STEPS.filter(e => e.forBusiness === isForBusiness);
  }

  isLarge(): boolean {
    return this._isLarge;
  }

  isMedium(): boolean {
    return this._isMedium;
  }
  isSmall(): boolean{
    return this._isSmall;
  }
  isXSmall(): boolean{
    return this._isXSmall;
  }
}
