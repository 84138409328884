<mat-card class="container" class="mat-component-background mat-elevation-z2">
  <mat-card-header>
    <div mat-card-avatar><mat-icon>start</mat-icon></div>
    <mat-card-title> Almost There </mat-card-title>
  </mat-card-header>
  <mat-card-content>
    <p>
      We need some company details from you. Please add the missing details, so
      your customers can find you and become a member.
    </p>

    <mat-horizontal-stepper [linear]="true" #stepper class="mat-component-background">
      <mat-step [stepControl]="companyDetailsFormGroup">
        <form [formGroup]="companyDetailsFormGroup">
          <ng-template matStepLabel>Fill out your company details</ng-template>
          <div fxLayout="row">
            <div fxFlex fxLayout="column" class="container-column">
              <mat-form-field appearance="outline">
                <mat-label>Company Name</mat-label>
                <input
                  matInput
                  placeholder="Ex. My Company B.V."
                  formControlName="companyName"
                />
                <mat-error
                  *ngIf="
                    companyDetailsFormGroup
                      .get('companyName')
                      .hasError('required') &&
                    companyDetailsFormGroup.get('companyName').touched
                  "
                >
                  <span>Company name is required</span>
                </mat-error>
              </mat-form-field>
              <mat-form-field appearance="outline">
                <mat-label>Description</mat-label>
                <input
                  matInput
                  placeholder="Ex. My company description"
                  formControlName="description"
                />
                <mat-error
                  *ngIf="
                    companyDetailsFormGroup
                      .get('description')
                      .hasError('required') &&
                    companyDetailsFormGroup.get('description').touched
                  "
                >
                  <span>Description is required</span>
                </mat-error>
              </mat-form-field>
              <mat-form-field appearance="outline">
                <mat-label>Website</mat-label>
                <input
                  matInput
                  placeholder="Ex. www.example.com"
                  formControlName="website"
                />
                <mat-error
                  *ngIf="
                    companyDetailsFormGroup
                      .get('website')
                      .hasError('required') &&
                    companyDetailsFormGroup.get('website').touched
                  "
                >
                  <span>Website is required</span>
                </mat-error>
                <mat-error
                  *ngIf="
                    companyDetailsFormGroup
                      .get('website')
                      .hasError('pattern') &&
                    companyDetailsFormGroup.get('website').touched
                  "
                >
                  <span>Not a valid url is provided</span>
                </mat-error>
              </mat-form-field>

              <mat-form-field appearance="outline">
                <mat-label>Company Email</mat-label>
                <input
                  type="email"
                  matInput
                  placeholder="Ex. info@yourcompany.com"
                  formControlName="companyEmail"
                />
                <mat-error
                  *ngIf="
                    companyDetailsFormGroup
                      .get('companyEmail')
                      .hasError('required') &&
                    companyDetailsFormGroup.get('companyEmail').touched
                  "
                >
                  <span>Company email is required</span>
                </mat-error>
                <mat-error
                  *ngIf="
                    companyDetailsFormGroup
                      .get('companyEmail')
                      .hasError('pattern') &&
                    companyDetailsFormGroup.get('companyEmail').touched
                  "
                >
                  <span>Not a valid email is provided</span>
                </mat-error>
              </mat-form-field>
            </div>
          </div>
          <div class="container-actions" fxLayout="row" fxLayoutAlign="start" fxLayoutGap="8px">
            <button mat-flat-button matStepperNext color="accent" (click)="saveCompany()" [disabled]="!companyDetailsFormGroup.valid">Next</button>
          </div>
        </form>
      </mat-step>
      <mat-step [stepControl]="companyLogoFormGroup">
        <form [formGroup]="companyLogoFormGroup">
          <ng-template matStepLabel>Upload your company logo</ng-template>
          <div fxLayout="row" class="container-logo">
            <div fxFlex>
              <ng-container *ngIf="(isImageSaved$| async); else elseTemplate">
                <img
                  [src]="companyLogoUrl$ | async"
                  class="logo"
                  alt="company-logo"
                />
              </ng-container>
              <ng-template #elseTemplate>
                <img [src]="placeholderUrl" class="logo" alt="company-logo" />
              </ng-template>
            </div>
          </div>
          <div class="container-actions" fxLayout="row" fxLayoutAlign="start" fxLayoutGap="8px">
            <button mat-flat-button matStepperPrevious>Back</button>
            <button
              mat-icon-button
              (click)="removeImage()"
              [disabled]="!(isImageSaved$| async)"
            >
              <mat-icon>delete</mat-icon>
            </button>
            <button type="button" mat-icon-button color="accent" (click)="fileInput.click()">
              <mat-icon>upload</mat-icon>
            </button>
            <input
              hidden
              (change)="fileChangeEvent($event)"
              #fileInput
              type="file"
              id="file"
              accept=".jpg,.jpeg,.png"
            />

            <button mat-flat-button matStepperNext color="accent" [disabled]="!this.companyLogoFormGroup.valid">Next</button>
          </div>
        </form>
      </mat-step>
      <mat-step>
        <ng-template matStepLabel>Done</ng-template>
        <p>You are now done.</p>
        <br/>
        <p>Head over to Store Locations to create your first store location.</p>
        <p>Head over to Promotions or Stamp Cards to engage with your customers.</p>
        <div class="container-actions" fxLayout="row" fxLayoutAlign="start" fxLayoutGap="8px">
          <button mat-flat-button matStepperPrevious>Back</button>
          <button mat-flat-button color="accent" (click)="finishOnBoarding()" >Done</button>
        </div>
      </mat-step>
    </mat-horizontal-stepper>
  </mat-card-content>
</mat-card>
