<div class="container">
    <mat-card appearance="outlined" class="mat-component-background mat-elevation-z2">
      <mat-card-header>
          <mat-card-title>Invoices</mat-card-title>
      </mat-card-header>
      <mat-card-content>
            <div *ngIf="hasUnpaidInvoice; else invoicesPaid" class="content-container">
                <p>You have an unpaid invoice. Please go to the invoice portal.</p>
            </div>
      </mat-card-content>
      <mat-card-actions>
        <div class="container-actions" fxLayout="row" fxLayoutAlign="space-between end">
          <button
            mat-button
            (click)="goToPaymentProviderCustomerPortal()"
            color="primary"
          >
            <mat-icon>payments</mat-icon><mat-icon>open_in_new</mat-icon>Invoice Portal
          </button>
          <div *ngIf="currentSubscriptionPlanOrder">
            <button
              mat-button
              (click)="cancelCurrentSubscriptionPlanOrder()"
            >
              <mat-icon>delete_outline</mat-icon>Cancel Plan
            </button>
          </div>
        </div>
      </mat-card-actions>
    </mat-card>
  </div>
  
  <ng-template #invoicesPaid>
    <div class="content-container">
        <p>You have no open invoices.</p>
        <p>For a complete invoice overview go to the Invoice Portal.</p>
    </div>
  </ng-template>
