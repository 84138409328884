<div class="container" fxLayout="row" fxLayoutAlign="start">
    <div *ngIf="!editMode; else showEdit">
      <div class="container-promotion" fxFlex>
        <app-promotion
          [promotion]="(viewModel$ | async)?.promotion"
          (onDeletePressed)="onDelete($event)"
          (onActivatePressed)="onActivate($event)"
          (onDeactivatePressed)="onDeactivate($event)"
          (onEditPressed)="onEditPressed($event)"
        ></app-promotion>
      </div>
    </div>
    <div class="container-promotion-logo">
      <app-promotion-logo [promotion]="(viewModel$ | async)?.promotion"></app-promotion-logo>
    </div>
  </div>

  <ng-template #showEdit>
    <app-promotion-edit
          [promotion]="(viewModel$ | async)?.promotion"
           (onCancelPressed)="onEditCancelPressed($event)"
           (onUpdateSuccess)="onUpdateSuccess($event)"
        ></app-promotion-edit>
  </ng-template>