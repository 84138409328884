<div class="container">
    <div fxLayout="column" fxLayoutAlign="center center">
      <div fxLayout="row" fxLayoutAlign="center start">
        <div fxLayout="column"
        >
          <app-signin (onSignInPressed)="signIn()" [content]="signInContent"></app-signin>
        </div>
      </div>
    </div>
  </div>
  