<div class="container">
  <div class="container-banner">
    <h1>Frequenty Asked Questions</h1>
    <div class="banner-content">
      <h2>
        We hope you'll find your answer here. If not, then feel free to
        <a href="/contact">contact</a> the Planet VG Chefs.
      </h2>
    </div>
  </div>

  <mat-accordion multi>
    <mat-expansion-panel class="mat-component-background">
      <mat-expansion-panel-header>
        <mat-panel-title>
          What kind of business is Planet VG most successful for?
        </mat-panel-title>
      </mat-expansion-panel-header>
      <p>
        Planet VG's promotions and stamp cards are successful for climate
        conscious and vegan businesses with a highly repeatable product or
        service.
      </p>
      <ul>
        <li>Cafes</li>
        <li>Coffeeshops</li>
        <li>Restaurants</li>
        <li>Fast Food Restaurants</li>
        <li>Retail Stores</li>
        <li>Nail Salons</li>
        <li>Hair & Beauty Salons</li>
        <li>Health Services</li>
        <li>Fitness Centers</li>
        <li>Yoga Studios</li>
        <li>Bakeries</li>
        <li>B&Bs</li>
        <li>Hotels</li>
        <li>Market Vendors</li>
        <li>Food Trucks</li>
        <li>Juice Bars</li>
        <li>Ice Cream Vendors</li>
        <li>Spas</li>
        <li>Car Washes</li>
        <li>Farmers Markets</li>
        <li>And many more ...</li>
      </ul>
    </mat-expansion-panel>
    <mat-expansion-panel class="mat-component-background">
      <mat-expansion-panel-header>
          <mat-panel-title>
              What problem does Planet VG solve?
          </mat-panel-title>
      </mat-expansion-panel-header>
      <p>
          Planet VG is a platform on which you can publish your promotions and stamp card programs.
          We provide all the necessary ingredients and tooling, which you can use to build your own successful
          promotion or stamp card program.
          There is no need to hire an expensive agency to build your promotion or stamp card program. With Planet VG’s
          user-friendly interface, you can easily create them yourself!
      </p>
  </mat-expansion-panel>
  <mat-expansion-panel class="mat-component-background">
      <mat-expansion-panel-header>
          <mat-panel-title>
              How long does my trial last?
          </mat-panel-title>
      </mat-expansion-panel-header>
      <p>
          The trial period lasts one year (or 365 days). After that your chosen plan gets activated and you will be billed
          automatically; monthly or annually.
      </p>
  </mat-expansion-panel>
  <mat-expansion-panel class="mat-component-background">
      <mat-expansion-panel-header>
          <mat-panel-title>
              Can I change subscription plans?
          </mat-panel-title>
      </mat-expansion-panel-header>
      <p>
          You can change your plans later on according to your needs.<br />Just be aware that when you change plan,
          certain features might be deactivated according to the chosen plan and your current settings.
      </p>
  </mat-expansion-panel>
  <mat-expansion-panel class="mat-component-background">
      <mat-expansion-panel-header>
          <mat-panel-title>
              What is meant by&nbsp;<em>Active Stamp Card</em>?
          </mat-panel-title>
      </mat-expansion-panel-header>
      <p>
          Depending on your subscription plan you can have a certain amount of active stamp card programs. Your active
          stamp card program is the stamp card that your customers see in their PlanetVG app and can collect stamps
          on.
          You can create as many stamp card programs as are included in your subscription plan.
          For example:<br />
          Your subscription plan is “Starters”, you have 1 active stamp card. You create a stamp card which is valid
          from January until February. During this period no other stamp card programs can be active. When this stamp
          card program ends at the end of February, you can set a new stamp card program active.
      </p>
  </mat-expansion-panel>
  <mat-expansion-panel class="mat-component-background">
      <mat-expansion-panel-header>
          <mat-panel-title>
              What is meant by&nbsp;<em>Active Promotion</em>?
          </mat-panel-title>
      </mat-expansion-panel-header>
      <p>
          Depending on your subscription plan you can have a certain amount of active promotion programs. Your active
          promotion program is the promotion that your customers see in their PlanetVG app and from which they can
          claim its voucher..
          You can create as many promotion programs as are included in your subscription plan.
          For example:<br />
          Your subscription plan is “Starters”, you have 1 active prmotion. You create a promotion which is valid from
          Wednesday until Sunday. During this period no other promotion programs can be active. When this promotion
          program ends on Sunday, you can set a new promotion program active.
      </p>
  </mat-expansion-panel>
  <mat-expansion-panel class="mat-component-background">
      <mat-expansion-panel-header>
          <mat-panel-title>
              What is meant by&nbsp;<em>Store</em>?
          </mat-panel-title>
      </mat-expansion-panel-header>
      <p>
          Depending on your subscription plan you can use your loyalty campaigns and promotions in a certain amount of
          your physical stores. The store is part of your company listing that your customers see in their PlanetVG
          app.<br />
          To help new customers easily determine whether your store is interesting to them, you can select what type
          of store you have:<br />
      </p>
      <ul>
          <li>Vegan</li>
          <li>Vegetarian</li>
          <li>Other</li>
      </ul>
      <p>
          And the following options:<br />
      </p>
      <ul>
          <li>Vegan Options</li>
          <li>Vegetarian Options</li>
          <li>Gluten Free Options</li>
          <li>Bio Organic</li>
          <li>Bio Dynamic</li>
          <li>Zero Waste</li>
          <li>Plastic Free</li>
      </ul>
  </mat-expansion-panel>
  <mat-expansion-panel class="mat-component-background">
      <mat-expansion-panel-header>
          <mat-panel-title>
              What are&nbsp;<em>Dashboard Analytics</em>?
          </mat-panel-title>
      </mat-expansion-panel-header>
      <p>
          On your dashboard you will see the following:
      </p>
      <ul>
          <li>
              Quick overview of your active store locations, promotions and stamp cards.
          </li>
          <li>Total Members Chart. Overview of your members.</li>
          <li>
              Promotion Chart. Every active promotion has a chart displaying the following:
              <ul>
                  <li>Engagements: the amount of people that claimed the voucher.</li>
                  <li>
                      Issued Vouchers: the amount of people that received a voucher.
                  </li>
                  <li>
                      Redeemed Vouchers: the amount of people that redeemed the voucher.
                  </li>
              </ul>
              The ratio between <em>issued vouchers</em> and
              <em>redeemed vouchers</em> is important to measure the actual success
              of your promotion.
          </li>
          <li>
              Stamp Card Chart. Every active stamp card has a chart
              displaying the following:
              <ul>
                  <li>
                      Participants: the amount of people that is saving on a stamp card.
                  </li>
                  <li>
                      Issued Vouchers: the amount of people that received a voucher.
                  </li>
                  <li>
                      Redeemed Vouchers: the amount of people that redeemed the voucher.
                  </li>
              </ul>
              The ratio between <em>issued vouchers</em> and
              <em>redeemed vouchers</em> is important to measure the actual success
              of your stamp card.
          </li>
      </ul>
  </mat-expansion-panel>
    <mat-expansion-panel class="mat-component-background">
      <mat-expansion-panel-header>
        <mat-panel-title>
          What's the difference between the Planet VG app and the Planet VG
          Store app?
        </mat-panel-title>
      </mat-expansion-panel-header>
      <p>
        The Planet VG app is the app for your customers. Using this app your
        customers can grab deals, get a stamp card, collect stamps, and
        vouchers. They can also manage their club memberships.
      </p>
      <p>
        The Planet VG Store app is the app for your employees. Using this app
        your employees can issue stamps for a specific stamp card or
        redeem vouchers.
      </p>
      <p><em>And, while we are at it;</em></p>
      <p>
        The Planet VG Partner Portal is the portal where
        you, as a partner, can manage your promotions, stamp cards, store
        locations and your company. You can also manage your subscription within
        the partner portal.
      </p>
    </mat-expansion-panel>
    <mat-expansion-panel class="mat-component-background">
      <mat-expansion-panel-header>
        <mat-panel-title>
          How long does it take to set up a promotion?
        </mat-panel-title>
      </mat-expansion-panel-header>
      <p>
        It's very easy to create a promotion for your customers. Make sure you have a
        nice image at hand. This image will be the first thing your customers will see.
      </p>
      <p>
        Once you have become a partner via the
        <a href="/register">Registration page</a> and filled out your company
        details, navigate to <strong>Promotions</strong> and press
        <strong>New</strong>.
      </p>
      <p>
        Your subscription plan determines how many promotions you can create and
        activate.
      </p>
    </mat-expansion-panel>
    <mat-expansion-panel class="mat-component-background">
      <mat-expansion-panel-header>
        <mat-panel-title>
          How long does it take to set up a stamp card?
        </mat-panel-title>
      </mat-expansion-panel-header>
      <p>
        It's very easy to create a stamp card for your customers. Make
        sure you have a nice image at hand. This image will be the first thing your customers will see.
      </p>
      <p>
        Once you have become a partner via the
        <a href="/register">Registration page</a> and filled out your company
        details, navigate to <strong>Stamp Cards</strong> and press
        <strong>New</strong>.
      </p>
      <p>
        Your subscription plan determines how many stamp cards you can
        create and activate.
      </p>
    </mat-expansion-panel>
    <mat-expansion-panel class="mat-component-background">
      <mat-expansion-panel-header>
        <mat-panel-title> How does the free trial work? </mat-panel-title>
      </mat-expansion-panel-header>
      <p>
        As a new partner you will receive a <strong>one year free trial</strong> or
        <strong>Amuse-Bouche</strong>, when you buy a subscription plan. When the trial period ends, you will
        receive your first invoice.
      </p>
    </mat-expansion-panel>
    <mat-expansion-panel class="mat-component-background">
      <mat-expansion-panel-header>
        <mat-panel-title> How are payments processed? </mat-panel-title>
      </mat-expansion-panel-header>
      <p>
        When you buy a subscription plan, you'll need to provide a payment method.
        We use <a href="https://www.stripe.com">Stripe</a> for handling all
        payments. This way we don't store your payment data. How your data is
        handled can be found in our
        <a href="/privacy-polict">privacy policy</a>.
      </p>
    </mat-expansion-panel>
    <mat-expansion-panel class="mat-component-background">
      <mat-expansion-panel-header>
        <mat-panel-title> When will I be billed? </mat-panel-title>
      </mat-expansion-panel-header>
      <p>
        You will receive your first invoice, when the <strong>one year free trial</strong> ends. Depending on your
        subscription plan you will either be
        billed <strong><em>monthly</em></strong> or <strong><em> annually (yearly)</em></strong>.
      </p>
    </mat-expansion-panel>
    <mat-expansion-panel class="mat-component-background">
      <mat-expansion-panel-header>
        <mat-panel-title> What is your privacy policy? </mat-panel-title>
      </mat-expansion-panel-header>
      <p>
        You can find our privacy policy on the
        <a href="/privacy-policy">Privacy Policy page</a>.
      </p>
    </mat-expansion-panel>
    <mat-expansion-panel class="mat-component-background">
      <mat-expansion-panel-header>
        <mat-panel-title> What are the terms of service? </mat-panel-title>
      </mat-expansion-panel-header>
      <p>
        You can find our user terms on the
        <a href="/user-terms">User Terms page</a> and the partner terms on the
        <a href="/partner-terms">Partner Terms page</a>.
      </p>
    </mat-expansion-panel>
    <mat-expansion-panel class="mat-component-background">
      <mat-expansion-panel-header>
        <mat-panel-title> Are there any setup costs? </mat-panel-title>
      </mat-expansion-panel-header>
      <p>No, there are no setup costs.</p>
    </mat-expansion-panel>
    <mat-expansion-panel class="mat-component-background">
      <mat-expansion-panel-header>
        <mat-panel-title>
          Can I cancel my plan at any time?
        </mat-panel-title>
      </mat-expansion-panel-header>
      <p>Please see <a href="/partner-terms" target="_blank" rel="noopener">Section 24.4. of the Partner Terms of
          Service</a> for the specific
        details on cancellation.</p>
    </mat-expansion-panel>
    <mat-expansion-panel class="mat-component-background">
      <mat-expansion-panel-header>
        <mat-panel-title> What does Planet VG do for the climate change? </mat-panel-title>
      </mat-expansion-panel-header>
      <p>
        Planet VG is committed to reduce the CO2 computer servers emit. Therefor we run our servers on renewable energy
        and
        promote a plant-based lifestyle.
      </p>
      <p>See our <a href="/climate-pledge">Climate Pledge</a> for more details.</p>
    </mat-expansion-panel>
    <mat-expansion-panel class="mat-component-background">
      <mat-expansion-panel-header>
        <mat-panel-title> Still have questions? </mat-panel-title>
      </mat-expansion-panel-header>
      <p>
        If you still have questions, feel free to contact us at any time. You
        can find the contact form on this page
        <a href="/contact">Contact page</a>.
      </p>
    </mat-expansion-panel>
  </mat-accordion>
</div>
<app-bottom-menu></app-bottom-menu>