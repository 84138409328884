import { Component, EventEmitter, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { emailRegex, urlRegex } from '../../validators/validation-regex';
import { Store } from '@ngrx/store';
import { selectBusinessAccount } from 'src/app/stores/global/app.selectors';
import { BusinessAccountModel } from 'src/app/models/business-account-model';
import { Observable, Subject, filter, of, takeUntil } from 'rxjs';
import { UpdateCompanyComponentStore } from 'src/app/stores/components/company-edit/update-company.component-store';
import { MatSnackBar } from '@angular/material/snack-bar';
import { CompanyLogoComponentStore } from 'src/app/stores/components/company-logo/company-logo.component-store';
import { UploadPhotoModel } from 'src/app/models/upload-photo-model';
import * as _ from 'lodash';
import { UpdateCompanyModel } from 'src/app/models/update-company-model';
import { SpinnerService } from 'src/app/services/spinner.service';
import { SpinnerDialogComponent } from '../dialogs/spinner-dialog/spinner-dialog.component';
import { MatDialogRef } from '@angular/material/dialog';
import { ValidationException } from '../../exceptions/validation-exception';

@Component({
  selector: 'app-get-started',
  templateUrl: './get-started.component.html',
  styleUrls: ['./get-started.component.scss']
})
export class GetStartedComponent {
  @Output()
  onOnBoardingFinished = new EventEmitter();

  public readonly minNameLength: number = 1;
  public readonly maxNameLength: number = 32;
  public readonly minDescriptionLength: number = 3;
  public readonly maxDescriptionLength: number = 256;

  readonly max_size = 512 * 1024 * 1024;
  readonly allowed_types = ['image/png', 'image/jpeg'];
  readonly max_height = 512;
  readonly max_width = 512;
  readonly placeholderUrl = 'https://via.placeholder.com/512';

  private loadingSpinnerDialogRef: MatDialogRef<SpinnerDialogComponent>; s

  ngUnsubscribeState = new Subject<void>();
  businessAccount$: Observable<BusinessAccountModel> = this.store.select(selectBusinessAccount);
  companyLogoUrl$: Observable<string>;
  companyId: string;

  isImageSaved$: Observable<boolean> = of(false);

  companyDetailsFormGroup: FormGroup;
  companyLogoFormGroup: FormGroup;

  constructor(
    private _formBuilder: FormBuilder,
    private store: Store<any>,
    private updateCompanyStore: UpdateCompanyComponentStore,
    private companyLogoComponentStore: CompanyLogoComponentStore,
    private snackBar: MatSnackBar,
    private spinnerService: SpinnerService,
  ) { }

  ngOnInit() {
    this.companyDetailsFormGroup = this._formBuilder.group({
      id: ['', Validators.required],
      companyName: ['', [Validators.required, Validators.minLength(this.minNameLength), Validators.maxLength(this.maxNameLength)]],
      description: ['', [Validators.required, Validators.minLength(this.minDescriptionLength), Validators.maxLength(this.maxDescriptionLength)]],
      website: ['', [Validators.required, Validators.pattern(urlRegex)]],
      companyEmail: ['', [Validators.required, Validators.pattern(emailRegex)]]
    });

    this.companyLogoFormGroup = this._formBuilder.group({
      uploadedLogo: ['', Validators.required]
    });

    this.store.select(selectBusinessAccount).pipe(takeUntil(this.ngUnsubscribeState), filter(result => Boolean(result))).subscribe(
      businessAccount => {
        this.companyId = businessAccount.company.id;
        this.companyDetailsFormGroup.patchValue({ 'id': businessAccount.company.id });
      });

    this.updateCompanyStore.loaded$.pipe(takeUntil(this.ngUnsubscribeState)).subscribe((loaded) => {
      this.hideSpinner(loaded);
    });

    this.updateCompanyStore.errorMessage$.pipe(takeUntil(this.ngUnsubscribeState)).subscribe((errorMessage) => {
      if (errorMessage !== null && errorMessage !== undefined) {
        this.updateCompanyStore.setInitial();
        if(errorMessage instanceof ValidationException){
          const validationViolationMessage = errorMessage.violations.map(v => v.message).join("\n");
          this.snackBar.open(validationViolationMessage, "Dismiss");
        }
        else {
          this.snackBar.open(errorMessage, "Dismiss");
        }
      }
    });

    this.companyLogoComponentStore.logo$.pipe(takeUntil(this.ngUnsubscribeState)).subscribe(logo => {
      if (logo !== null && logo !== undefined) {
        this.isImageSaved$ = of(true);
        this.companyLogoUrl$ = of(logo.url);
        this.companyLogoFormGroup.patchValue({ 'uploadedLogo': true });
      }
      else {
        this.isImageSaved$ = of(false);
        this.companyLogoUrl$ = of(this.placeholderUrl);
        this.companyLogoFormGroup.patchValue({ 'uploadedLogo': '' });
      }
    });
  }

  ngOnDestroy() {
    this.ngUnsubscribeState.next();
    this.ngUnsubscribeState.complete();
  }

  ngOnChanges(changes) {
    if (changes.company) {
      this.companyLogoComponentStore.getCompanyLogo({ companyId: this.companyId });
    }
  }

  saveCompany() {
    this.showSpinner();

    if (this.companyDetailsFormGroup.valid) {
      const updateCompany = new UpdateCompanyModel();
      updateCompany.id = this.companyDetailsFormGroup.value.id;
      updateCompany.name = this.companyDetailsFormGroup.value.companyName;
      updateCompany.description = this.companyDetailsFormGroup.value.description;
      updateCompany.email = this.companyDetailsFormGroup.value.companyEmail;
      updateCompany.url = this.companyDetailsFormGroup.value.website;

      this.updateCompanyStore.updateCompany(updateCompany);
    }
  }

  finishOnBoarding(){
    
    this.onOnBoardingFinished.emit();
  }

  fileChangeEvent(fileInput: any) {
    if (fileInput?.target?.files && fileInput?.target?.files[0]) {
      if (fileInput.target.files[0].size > this.max_size) {
        const errorMessage =
          'Maximum size allowed is ' + this.max_size / 1000 + 'Mb';
        this.snackBar.open(errorMessage, "Dismiss");
        return false;
      }

      if (!_.includes(this.allowed_types, fileInput.target.files[0].type)) {
        const errorMessage = 'Only Images are allowed ( JPG | PNG )';
        this.snackBar.open(errorMessage, "Dismiss");
        return false;
      }

      const uploadPhoto = new UploadPhotoModel();

      const reader = new FileReader();
      reader.onload = (e: any) => {
        const image = new Image();
        image.src = e.target.result;
        image.onload = rs => {
          const img_height = rs.currentTarget['height'];
          const img_width = rs.currentTarget['width'];
          if (img_height > this.max_height && img_width > this.max_width) {
            const errorMessage =
              'Maximum dimensions allowed ' +
              this.max_height +
              '*' +
              this.max_width +
              'px';
            this.snackBar.open(errorMessage, "Dismiss");
            return false;
          } else {
            const imgBase64Path = e.target.result;
            uploadPhoto.fileContentBase64 = imgBase64Path.split(',')[1];
            this.companyLogoComponentStore.uploadCompanyLogo({ companyId: this.companyId, uploadPhoto: uploadPhoto });

            return true;
          }
        };
      };

      const logo = fileInput.target.files[0];

      uploadPhoto.title = 'Company Logo';
      uploadPhoto.fileName = 'logo' + this._determineFileExtension(logo.type);

      reader.readAsDataURL(logo);
      return true;
    }
    return true;
  }

  removeImage() {
    this.companyLogoComponentStore.deleteCompanyLogo({ companyId: this.companyId });
  }

  _determineFileExtension(fileType: string): string {
    switch (fileType) {
      case 'image/jpg':
      case 'image/jpeg':
        return '.jpg';
      case 'image/png':
        return '.png';
    }
    throw new Error('Unsupported file type:' + fileType);
  }

  private showSpinner() {
    this.loadingSpinnerDialogRef = this.spinnerService.show();
  }

  private hideSpinner(loaded: boolean) {
    if (loaded && this.loadingSpinnerDialogRef !== null) {
      this.spinnerService.hide(this.loadingSpinnerDialogRef);
      this.loadingSpinnerDialogRef = null;
    }
  }
}
