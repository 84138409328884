<div class="container">
  <div [ngClass]="{
    'container-item-card-xlarge': isXLarge(),
    'container-item-card-large': isLarge(),
    'container-item-card-medium': isMedium(),
    'container-item-card-small': isSmall(),
    'container-item-card-xsmall': isXSmall()
  }">
    <mat-card appearance="outlined" class="mat-component-background mat-elevation-z2">
      <mat-card-header>
        <div mat-card-avatar class="chefs-special-avatar"></div>
        <mat-card-title>Chef's Special</mat-card-title>
        <mat-card-subtitle>Serving All Day Every Day</mat-card-subtitle>
      </mat-card-header>
      <div *ngIf="!isXSmall(); else xSmallContentImage">
        <img src="/assets/img/banners/small/product-banner-chefs-special-s.webp"
          alt="chefs-special-free-amuse-bouche" />
      </div>
      <mat-card-actions></mat-card-actions>
    </mat-card>
  </div>

  <div [ngClass]="{
    'container-item-xlarge': isXLarge(),
    'container-item-large': isLarge(),
    'container-item-medium': isMedium(),
    'container-item-small': isSmall(),
    'container-item-xsmall': isXSmall()
  }">
    <h1>Introductory Offer</h1>
    <h2>Planet VG wants to empower you to grow your business sustainably.</h2>
    <mat-list>
      <mat-list-item [ngClass]="{
        'list-item-xsmall': isXSmall()
      }">
        <span matListItemIcon>
          <mat-icon color="primary">eco</mat-icon>
        </span>
        <div matListItemTitle class="list-item-title">Try out our complementary Amuse-Bouche.</div>
      </mat-list-item>
      <mat-list-item [ngClass]="{
        'list-item-xsmall': isXSmall()
      }">
        <span matListItemIcon>
          <mat-icon color="primary">eco</mat-icon>
        </span>
        <div matListItemTitle class="list-item-title">Receive a free year trial.</div>
      </mat-list-item>
      <mat-list-item [ngClass]="{
        'list-item-xsmall': isXSmall()
      }">
        <span matListItemIcon>
          <mat-icon color="primary">eco</mat-icon>
        </span>
        <div matListItemTitle class="list-item-title">Designed for human and animal enjoyment.</div>
      </mat-list-item>
    </mat-list>
  </div>
</div>

<ng-template #xSmallContentImage>
  <div *ngIf="isXSmall();">
    <img src="/assets/img/banners/xsmall/product-banner-chefs-special-xs.webp" alt="chefs-special-free-amuse-bouche" />
  </div>
</ng-template>