<div class="container">
  <div [ngClass]="{
    'container-item-card-xlarge': isXLarge(),
    'container-item-card-large': isLarge(),
    'container-item-card-medium': isMedium(),
    'container-item-card-small': isSmall(),
    'container-item-card-xsmall': isXSmall()
  }">
    <mat-card appearance="outlined" class="column-card mat-component-background mat-elevation-z2">
      <mat-card-header>
        <mat-card-title-group>
          <mat-card-title>Let’s start saving</mat-card-title>
          <img class="pvg-icon" src="/assets/img/planetvg_icon.png" alt="planetvg-logo">
        </mat-card-title-group>
      </mat-card-header>
      <div *ngIf="!isXSmall(); else xSmallContentImage">
        <img src="/assets/img/banners/small/product-banner-lets-start-saving-s.webp"
          alt="lets-start-saving-become-a-partner" />
      </div>
      <mat-card-actions class="container-card-actions">
        <button mat-raised-button color="primary" [routerLink]="['/register']">
          <mat-icon>diversity_1</mat-icon>
          Become Partner
        </button>
      </mat-card-actions>
    </mat-card>
  </div>

  <div [ngClass]="{
    'container-item-xlarge': isXLarge(),
    'container-item-large': isLarge(),
    'container-item-medium': isMedium(),
    'container-item-small': isSmall(),
    'container-item-xsmall': isXSmall()
  }">
    <h1>Partnership</h1>
    <h2>Become a partner. Together we can make the world a better place for humans and animals.</h2>
    <mat-list>
      <mat-list-item [ngClass]="{
        'list-item-xsmall': isXSmall()
      }">
        <span matListItemIcon>
          <mat-icon color="primary">eco</mat-icon>
        </span>
        <div matListItemTitle class="list-item-title">Make your customers beloved members.</div>
      </mat-list-item>
      <mat-list-item [ngClass]="{
        'list-item-xsmall': isXSmall()
      }">
        <span matListItemIcon>
          <mat-icon color="primary">eco</mat-icon>
        </span>
        <div matListItemTitle class="list-item-title">Empower your customers with their animal rights ambitions.</div>
      </mat-list-item>
      <mat-list-item [ngClass]="{
        'list-item-xsmall': isXSmall()
      }">
        <span matListItemIcon>
          <mat-icon color="primary">eco</mat-icon>
        </span>
        <div matListItemTitle class="list-item-title">Empower your customers with their climate ambitions.</div>
      </mat-list-item>
    </mat-list>
  </div>
</div>

<ng-template #xSmallContentImage>
  <div *ngIf="isXSmall();">
    <img src="/assets/img/banners/xsmall/product-banner-lets-start-saving-xs.webp"
      alt="lets-start-saving-become-a-partner" />
  </div>
</ng-template>