import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { BillingDetailsModel } from 'src/app/models/billing-details-model';

@Component({
  selector: 'app-billing-details',
  templateUrl: './billing-details.component.html',
  styleUrls: ['./billing-details.component.scss']
})
export class BillingDetailsComponent implements OnInit {
  @Input()
  billingDetails : BillingDetailsModel;
  
  @Output()
  onEditPressed = new EventEmitter();
  
  ngOnInit(): void {
    // Empty
  }

  edit(){
    this.onEditPressed.emit(null);
  }
}
