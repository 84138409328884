<div class="container">
    <div
    fxLayout="row wrap"
    fxLayout.lt-sm="column"
    fxLayoutAlign="space-around center"
    fxLayoutGap="16px grid"
  >
    <div
      *ngIf="
        (viewModel$ | async)?.loading;
        else tryAgain
      ">
      <div class="container-banner">
        <p>You will be redirected to a stripe billing portal.</p>
        <p> If it not this url: https://billing.stripe.com, please contact support.</p>
      </div>
      <div class="spinner-container" fxLayout="column" fxLayoutAlign="center center">
        <mat-spinner diameter="80"></mat-spinner>
      </div>
    </div>
  </div>
</div>

<ng-template #tryAgain>
    <div
    *ngIf="
        (viewModel$ | async)?.loaded && !(viewModel$ | async)?.success"
      fxFlex="0 1 calc(33.3% - 32px)"
      fxFlex.lt-md="0 1 calc(50% - 32px)"
      fxFlex.lt-sm="100%"
    >
      <p>something went wrong with creating a session for you. Please try again.</p>>
    </div>
  </ng-template>
