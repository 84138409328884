import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { Observable, of } from 'rxjs';
import { businessAccountGetAction, clearSessionAction } from 'src/app/stores/global/app.actions';

@Component({
  selector: 'app-checkout-success',
  templateUrl: './checkout-success.component.html',
  styleUrls: ['./checkout-success.component.scss']
})
export class CheckoutSuccessComponent implements OnInit {
  timeLeft: number = 30;
  timerInterval: any;
  showNavigateButton$: Observable<boolean>;

  constructor(private router: Router, private store: Store, private oidcSecurityService : OidcSecurityService) {
    // Do nothing
  }

  ngOnInit(): void {
    // Do nothing
    this.startTimer();
  }

  startTimer(): void {
    this.timerInterval = setInterval(() => {
      if (this.timeLeft > 0) {
        this.timeLeft--;
      } else {
        clearInterval(this.timerInterval);
        this.showNavigateButton$ = of(true);
        this.store.dispatch(clearSessionAction());
        this.store.dispatch(businessAccountGetAction());
        this.router.navigate(['/dashboard']);
        // HACK: Refresh the session, forceRefreshSession, uses the persisted token and doesn't trigger the userdata changed event.          
        this.oidcSecurityService.authorize(null, { customParams: { prompt: 'none' } });
      }
    }, 1000)
  }

  navigateManually(): void {
    this.store.dispatch(clearSessionAction());
    this.store.dispatch(businessAccountGetAction());
    this.router.navigate(['/dashboard']);
    // HACK: Refresh the session, forceRefreshSession, uses the persisted token and doesn't trigger the userdata changed event.          
    this.oidcSecurityService.authorize(null, { customParams: { prompt: 'none' } });
  }
}
