<div class="container">
    <mat-card class="mat-component-background mat-elevation-z2">
      <mat-card-header>
        <mat-card-title> Billing Details </mat-card-title>
      </mat-card-header>
      <mat-card-content>
          <div fxFlex fxLayout="column" class="container-column">
            <mat-form-field appearance="outline">
                <mat-label>Billing Id</mat-label>
                <input
                  matInput
                  readonly
                  value="{{ billingDetails?.externalId }}"
                />
            </mat-form-field>
            <mat-form-field appearance="outline">
                <mat-label>Vat Number</mat-label>
                <input
                  matInput
                  readonly
                  value="{{ billingDetails?.vatNumber }}"
                />
            </mat-form-field>
            <mat-form-field appearance="outline">
              <mat-label>Phone Number</mat-label>
              <input
                matInput
                readonly
                value="{{ billingDetails?.phoneNumber }}"
              />
            </mat-form-field>
            <mat-form-field appearance="outline">
              <mat-label>Billing Email</mat-label>
              <input
                matInput
                readonly
                value="{{ billingDetails?.email }}"
              />
            </mat-form-field>
            <mat-form-field appearance="outline">
              <mat-label>Address Line 1</mat-label>
              <input
                matInput
                readonly
                value="{{ billingDetails?.addressLine1 }}"
              />
            </mat-form-field>
            <mat-form-field appearance="outline">
              <mat-label>Address Line 2</mat-label>
              <input
                matInput
                readonly
                value="{{ billingDetails?.addressLine2 }}"
              />
            </mat-form-field>
            <mat-form-field appearance="outline">
              <mat-label>Postal Code</mat-label>
              <input
                matInput
                readonly
                value="{{ billingDetails?.postalCode }}"
              />
            </mat-form-field>
            <mat-form-field appearance="outline">
              <mat-label>City</mat-label>
              <input
                matInput
                readonly
                value="{{ billingDetails?.city }}"
              />
            </mat-form-field>
            <mat-form-field appearance="outline">
              <mat-label>Country</mat-label>
              <input
                matInput
                readonly
                value="{{ billingDetails?.country }}"
              />
            </mat-form-field>
          </div>
      </mat-card-content>
      <mat-card-actions>
        <div class="container-actions" fxLayout="row" fxLayoutAlign="end">
          <button mat-icon-button (click)="edit()">
            <mat-icon>edit</mat-icon>
          </button>
        </div>
      </mat-card-actions>
    </mat-card>
  </div>
  