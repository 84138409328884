<div class="container" fxLayout="row" fxLayoutAlign="start">
  <div *ngIf="!editMode; else showEdit">
    <div class="container-campaign" fxFlex>
      <app-campaign
        [campaign]="(viewModel$ | async)?.campaign"
        (onDeletePressed)="onDelete($event)"
        (onActivatePressed)="onActivate($event)"
        (onEditPressed)="onEdit($event)"
      ></app-campaign>
    </div>
  </div>
  <div class="container-campaign-logo">
    <app-campaign-logo
      [campaign]="(viewModel$ | async)?.campaign"
    ></app-campaign-logo>
  </div>
</div>

<ng-template #showEdit>
  <app-campaign-edit
    [campaign]="(viewModel$ | async)?.campaign"
    (onCancelPressed)="onEditCancelPressed($event)"
    (onUpdateSuccess)="onUpdateSuccess($event)"
  ></app-campaign-edit>
</ng-template>
