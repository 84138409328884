import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { StoreLocationDetailComponentStore } from 'src/app/stores/components/store-location-detail/store-location-detail.component-store';
import { UpdateStoreLocationModel } from 'src/app/models/update-store-location-model';
import { UpdateStoreLocationComponentStore } from 'src/app/stores/components/store-location-edit/update-store-location.component-store';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ConfirmationDialogComponent } from 'src/app/core/components/dialogs/confirmation-dialog/confirmation-dialog.component';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { StoreLocationModel } from 'src/app/models/store-location-model';
import { DeleteStoreLocationComponentStore } from 'src/app/stores/components/store-locations-delete/delete-store-location.component-store';
import { MatSnackBar } from '@angular/material/snack-bar';
import { StoreUserComponentStore } from 'src/app/stores/components/store-user-details/store-user.component-store';
import { StoreUserModel } from 'src/app/models/store-user-model';
import { NoActiveSubscriptionException } from 'src/app/core/exceptions/no-active-subscription-exception';
import { MaximumActiveStoreLocationsExceededException } from 'src/app/core/exceptions/maximum-active-store-locations-exceeded-exception';
import { SpinnerService } from 'src/app/services/spinner.service';
import { SpinnerDialogComponent } from 'src/app/core/components/dialogs/spinner-dialog/spinner-dialog.component';
import { Store } from '@ngrx/store';
import { selectBusinessAccount } from 'src/app/stores/global/app.selectors';
import { ValidationException } from 'src/app/core/exceptions/validation-exception';
import { StoreLocationOption } from 'src/app/models/store-location-option-model';
import { PasswordDialogComponent } from 'src/app/core/components/dialogs/password-dialog/password-dialog.component';

@Component({
  selector: 'app-store-location-detail-page',
  templateUrl: './store-location-detail-page.component.html',
  styleUrls: ['./store-location-detail-page.component.scss']
})
export class StoreLocationDetailPageComponent implements OnInit {
  public minNameLength: number = 1;
  public maxNameLength: number = 32;
  public minDescriptionLength: number = 3;
  public maxDescriptionLength: number = 256;
  public minAddressLineLength : number = 3;
  public maxAddressLineLength : number = 128;
  public minPostalCodeLength : number = 1;
  public maxPostalCodeLength : number = 32;
  public minCityLength : number = 3;
  public maxCityLength : number = 128;
  public maxCountryLength : number = 32;
  public minPhoneNumberLength : number = 8;
  public maxPhoneNumberLength : number = 32;

  storeUserViewModel$ = this.storeUserComponentStore.viewModel$;
  viewModel$ = this.storeLocationDetailComponentStore.viewModel$;

  readMode: boolean = true;
  showStoreUser: boolean = false;
  storeLocationDetailsFormGroup: UntypedFormGroup;
  companyId: string;

  ngUnsubscribeState = new Subject<void>();
  private storeLocation: StoreLocationModel;
  private storeUser: StoreUserModel;
  private loadingSpinnerDialogRef: MatDialogRef<SpinnerDialogComponent>;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private storeLocationDetailComponentStore: StoreLocationDetailComponentStore,
    private formBuilder: UntypedFormBuilder,
    private updateStoreLocationComponentStore: UpdateStoreLocationComponentStore,
    private dialog: MatDialog,
    private acknowledgeDialog: MatDialog,
    private deleteStoreLocationComponentStore: DeleteStoreLocationComponentStore,
    private snackBar: MatSnackBar,
    private storeUserComponentStore: StoreUserComponentStore,
    private spinnerService: SpinnerService,
    private readonly store: Store
  ) { }

  ngOnInit(): void {
    this.route.params.subscribe(params => {
      this.store.select(selectBusinessAccount).pipe(filter(result => Boolean(result))).subscribe(businessAccount => {
        const gettingStartedCompanyDetails: boolean = !businessAccount.company.name || businessAccount.company.name === '';
        const gettingStartedCompanyLogo: boolean = !businessAccount.company.logo;
        if (gettingStartedCompanyDetails || gettingStartedCompanyLogo) {
          this.router.navigate(['/getting-started']);
        }
        else {
          this.companyId = params.companyId;
          this.storeUserComponentStore.setInitial();
          this.showSpinner();
          this.storeLocationDetailComponentStore.getStoreLocation({ companyId: params.companyId, storeLocationId: params.storeLocationId });
        }
      });
    });

    this.storeLocationDetailsFormGroup = this.formBuilder.group({
      id: ['', Validators.required],
      name: ['', [Validators.required, Validators.minLength(this.minNameLength), Validators.maxLength(this.maxNameLength)]],
      description: ['', [Validators.required, Validators.minLength(this.minDescriptionLength), Validators.maxLength(this.maxDescriptionLength)]],
      addressLine1: ['', [Validators.required, Validators.minLength(this.minAddressLineLength), Validators.maxLength(this.maxAddressLineLength)]],
      addressLine2: ['', [Validators.minLength(this.minAddressLineLength), Validators.maxLength(this.maxAddressLineLength)]],
      postalCode: ['', [Validators.required, Validators.minLength(this.minPostalCodeLength), Validators.maxLength(this.maxPostalCodeLength)]],
      city: ['', [Validators.required, Validators.minLength(this.minCityLength), Validators.maxLength(this.maxCityLength)]],
      country: ['', [Validators.required, Validators.maxLength(this.maxCountryLength)]],
      phoneNumber: ['', [Validators.required, Validators.minLength(this.minPhoneNumberLength), Validators.maxLength(this.maxPhoneNumberLength)]],
      type: ['', [Validators.required]],
      category: ['', [Validators.required]],
      optionVegan: [''],
      optionVegetarian: [''],
      optionVeganOptions: [''],
      optionVegetarianOptions: [''],
      optionGlutenFreeOptions: [''],
      optionBioOrganic: [''],
      optionBioDynamic: [''],
      optionZeroWaste: [''],
      optionPlasticFree: [''],
    });

    this.storeLocationDetailComponentStore.viewModel$.pipe(takeUntil(this.ngUnsubscribeState), filter(result => Boolean(result))).subscribe(viewModel => {
      if (viewModel.storeLocation === null || viewModel.storeLocation === undefined) {
        return;
      }

      this.storeLocation = viewModel.storeLocation;

      if (this.storeLocation.isActive()) {
        this.storeUserComponentStore.setInitial();
        this.storeUserComponentStore.getStoreUser({ companyId: viewModel.storeLocation.company.id, storeLocationId: viewModel.storeLocation.id })
      }
      else {
        this.storeUserComponentStore.setInitial();
        this.showStoreUser = false;
      }
    });

    this.storeUserViewModel$.pipe(takeUntil(this.ngUnsubscribeState)).subscribe(storeUserViewModel => {
      let showStoreUser = false;
      if (this.storeLocation?.isActive()
        && storeUserViewModel.storeUser !== null
        && storeUserViewModel.storeUser !== undefined) {
        this.storeUser = storeUserViewModel.storeUser;
        showStoreUser = true;
      }
      this.showStoreUser = showStoreUser;
    });

    this.storeUserComponentStore.newPassword$.pipe(takeUntil(this.ngUnsubscribeState)).subscribe((newPassword) => {
      if (newPassword !== null && newPassword !== undefined) {
        this.hideSpinner(true);
        this.acknowledgeDialog.open(PasswordDialogComponent, {
          data: {
            title: "New Password Generated",
            message: "Here is the new password for the store user account. Please save it securely, because, we won\'t store it for you. Password: ",
            data: newPassword,
          }
        });
      }
    });

    this.deleteStoreLocationComponentStore.success$.pipe(takeUntil(this.ngUnsubscribeState)).subscribe((result) => {
      if (result) {
        this.storeUserComponentStore.setInitial();
        this.deleteStoreLocationComponentStore.setInitial();
        this.snackBar.open("Store location archived successfully", "Dismiss");
        this.router.navigate(['/store-locations']);
      }
    });

    this.deleteStoreLocationComponentStore.errorMessage$.pipe(takeUntil(this.ngUnsubscribeState)).subscribe((errorMessage) => {
      if (errorMessage !== null && errorMessage !== undefined) {
        this.deleteStoreLocationComponentStore.setInitial();

        if (errorMessage instanceof ValidationException) {
          const validationViolationMessage = errorMessage.violations.map(v => v.message).join("\n");
          this.snackBar.open(validationViolationMessage, "Dismiss");
        }
        else {
          this.snackBar.open(errorMessage, "Dismiss");
        }
      }
    });

    this.updateStoreLocationComponentStore.success$.pipe(takeUntil(this.ngUnsubscribeState)).subscribe((result) => {
      if (result) {
        this.updateStoreLocationComponentStore.setInitial();
        this.snackBar.open("Store location updated successfully", "Dismiss");
        this.router.navigate(['/store-locations']);
      }
    });

    this.updateStoreLocationComponentStore.errorMessage$.pipe(takeUntil(this.ngUnsubscribeState)).subscribe((errorMessage) => {
      if (errorMessage !== null && errorMessage !== undefined) {
        this.updateStoreLocationComponentStore.setInitial();

        let displayErrorMessage = "Something went wrong.";
        if (errorMessage instanceof NoActiveSubscriptionException) {
          displayErrorMessage = 'No active subscriptionplan order. Please buy a subscriptionplan first.';
        }
        else if (errorMessage instanceof MaximumActiveStoreLocationsExceededException) {
          displayErrorMessage = 'You have reached the maximum amount of active store locations with your current subscription. If you want more active store location, consider upgrading your subscriptionplan.';
        }
        if (errorMessage instanceof ValidationException) {
          const validationViolationMessage = errorMessage.violations.map(v => v.message).join("\n");
          this.snackBar.open(validationViolationMessage, "Dismiss");
        }
        else {
          this.snackBar.open(displayErrorMessage, "Dismiss");
        }
      }
    });

    this.storeLocationDetailComponentStore.loaded$.pipe(takeUntil(this.ngUnsubscribeState)).subscribe((loaded) => {
      this.hideSpinner(loaded);
    });

    this.updateStoreLocationComponentStore.loaded$.pipe(takeUntil(this.ngUnsubscribeState)).subscribe((loaded) => {
      this.hideSpinner(loaded);
    });

    this.deleteStoreLocationComponentStore.loaded$.pipe(takeUntil(this.ngUnsubscribeState)).subscribe((loaded) => {
      this.hideSpinner(loaded);
    });

    this.storeUserComponentStore.loaded$.pipe(takeUntil(this.ngUnsubscribeState)).subscribe((loaded) => {
      this.hideSpinner(loaded);
    });
  }

  ngOnDestroy() {
    this.ngUnsubscribeState.next();
    this.ngUnsubscribeState.complete();
  }

  onEdit(event: any) {
    this.readMode = false;
  }

  onCancel(event: any) {
    this.readMode = true;
    // Reset the form data
  }

  onSave(event: any) {
    // Dispatch the save action
    if (this.storeLocationDetailsFormGroup.valid) {
      this.showSpinner();
      this.updateStoreLocation();
    }
  }

  onDelete(event: any) {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      data: {
        title: "Are you sure?",
        message: "You want to archive this store location",
        data: this.storeLocation.name,
        confirm: "Archive"
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.showSpinner();
        this.deleteStoreLocationComponentStore.deleteStoreLocation({ companyId: this.storeLocation.company.id, storeLocationId: this.storeLocation.id });
      }
    });
  }

  onGenerateNewPassword($event: any) {
    const dialogRef = this.acknowledgeDialog.open(ConfirmationDialogComponent, {
      data: {
        title: "Are you sure?",
        message: "You want to generate a new password for this store user?",
        data: this.storeLocation.name,
        confirm: "Generate New Password"
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.showSpinner();
        this.storeUserComponentStore.generateNewPassword({ companyId: this.storeLocation.company.id, storeLocationId: this.storeLocation.id, storeUserId: this.storeUser.id });
      }
    });
  }

  onActivate($event: any) {
    this.showSpinner();
    const updateStoreLocation = this.buildUpdateStoreLocationModel();
    updateStoreLocation.storeLocationStatus = "ACTIVE";
    this.updateStoreLocationComponentStore.updateStoreLocation({ companyId: this.companyId, updateStoreLocation: updateStoreLocation });
  }

  onDeactivate($event: any) {
    this.showSpinner();
    const updateStoreLocation = this.buildUpdateStoreLocationModel();
    updateStoreLocation.storeLocationStatus = "INACTIVE";
    this.updateStoreLocationComponentStore.updateStoreLocation({ companyId: this.companyId, updateStoreLocation: updateStoreLocation });
  }

  private buildUpdateStoreLocationModel() {
    const updateStoreLocation = new UpdateStoreLocationModel();
    updateStoreLocation.id = this.storeLocation.id.toString();
    updateStoreLocation.name = this.storeLocation.name;
    updateStoreLocation.description = this.storeLocation.description;
    updateStoreLocation.addressLine1 = this.storeLocation.addressLine1;
    updateStoreLocation.addressLine2 = this.storeLocation.addressLine2;
    updateStoreLocation.postalCode = this.storeLocation.postalCode;
    updateStoreLocation.city = this.storeLocation.city;
    updateStoreLocation.country = this.storeLocation.country;
    updateStoreLocation.phoneNumber = this.storeLocation.phoneNumber;
    updateStoreLocation.storeLocationType = this.storeLocation.storeLocationType;
    updateStoreLocation.storeLocationCategory = this.storeLocation.storeLocationCategory;
    updateStoreLocation.storeLocationOptions = this.storeLocation.storeLocationOptions;
    return updateStoreLocation;
  }

  private updateStoreLocation() {
    const updateStoreLocation = new UpdateStoreLocationModel();
    updateStoreLocation.id = this.storeLocationDetailsFormGroup.value.id;
    updateStoreLocation.name = this.storeLocationDetailsFormGroup.value.name;
    updateStoreLocation.description = this.storeLocationDetailsFormGroup.value.description;
    updateStoreLocation.addressLine1 = this.storeLocationDetailsFormGroup.value.addressLine1;
    updateStoreLocation.addressLine2 = this.storeLocationDetailsFormGroup.value.addressLine2;
    updateStoreLocation.postalCode = this.storeLocationDetailsFormGroup.value.postalCode;
    updateStoreLocation.city = this.storeLocationDetailsFormGroup.value.city;
    updateStoreLocation.country = this.storeLocationDetailsFormGroup.value.country;
    updateStoreLocation.phoneNumber = this.storeLocationDetailsFormGroup.value.phoneNumber;
    updateStoreLocation.storeLocationStatus = this.storeLocation.storeLocationStatus;
    updateStoreLocation.storeLocationType = this.storeLocation.storeLocationType;
    updateStoreLocation.storeLocationCategory = this.storeLocation.storeLocationCategory;
    updateStoreLocation.storeLocationOptions = this._getStoreLocationOptionsFromForm();

    this.updateStoreLocationComponentStore.updateStoreLocation({ companyId: this.companyId, updateStoreLocation: updateStoreLocation });
  }

  private showSpinner() {
    this.loadingSpinnerDialogRef = this.spinnerService.show();
  }

  private hideSpinner(loaded: boolean) {
    if (loaded && this.loadingSpinnerDialogRef !== null) {
      this.spinnerService.hide(this.loadingSpinnerDialogRef);
      this.loadingSpinnerDialogRef = null;
    }
  }

  private _getStoreLocationOptionsFromForm(): StoreLocationOption[] {
    let storeLocationOptions: Array<StoreLocationOption> = [];

    if (this.storeLocationDetailsFormGroup.value.optionVeganOptions) {
      storeLocationOptions.push(StoreLocationOption.VEGAN_OPTIONS);
    }

    if (this.storeLocationDetailsFormGroup.value.optionVegetarianOptions) {
      storeLocationOptions.push(StoreLocationOption.VEGETARIAN_OPTIONS);
    }

    if (this.storeLocationDetailsFormGroup.value.optionGlutenFreeOptions) {
      storeLocationOptions.push(StoreLocationOption.GLUTEN_FREE_OPTIONS);
    }

    if (this.storeLocationDetailsFormGroup.value.optionBioOrganic) {
      storeLocationOptions.push(StoreLocationOption.BIO_ORGANIC);
    }

    if (this.storeLocationDetailsFormGroup.value.optionBioDynamic) {
      storeLocationOptions.push(StoreLocationOption.BIO_DYNAMIC);
    }

    if (this.storeLocationDetailsFormGroup.value.optionZeroWaste) {
      storeLocationOptions.push(StoreLocationOption.ZERO_WASTE);
    }

    if (this.storeLocationDetailsFormGroup.value.optionPlasticFree) {
      storeLocationOptions.push(StoreLocationOption.PLASTIC_FREE);
    }

    return storeLocationOptions;
  }
}
