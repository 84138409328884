<div class="container">
  <mat-card
    appearance="outlined"
    class="mat-component-background mat-elevation-z2"
  >
    <mat-card-header>
      <div mat-card-avatar class="{{ getExplanationStepCssClass() }}"></div>
      <mat-card-title>
        {{ explanationStep.title }}
      </mat-card-title>
      <mat-card-subtitle>
        {{ explanationStep.subtitle }}
      </mat-card-subtitle>
    </mat-card-header>
    
      <img
        mat-card-image
        src="{{ explanationStep.imageUrl }}"
        alt="Explanation Step {{ explanationStep.title }}"
      />

    <mat-card-content class="container-card-content">
      <div class="content">
          {{ explanationStep.description }}
      </div>
    </mat-card-content>
    <mat-card-actions
      fxFlex
      fxLayout="column"
      fxLayoutAlign="space-between end"
    >
      <div
        *ngIf="explanationStep.showPricingAction"
        class="container-card-actions"
      >
        <button mat-raised-button color="primary" routerLink="/pricing">
          Try the Amuse-Bouche
        </button>
      </div>
    </mat-card-actions>
  </mat-card>
</div>
